import { useState } from "react";
import styles from "./ProductCard.module.css";
import { useMediaQuery } from "react-responsive";

interface Props {
  title: string;
  description: string;
  imgSrc: string;
}

const ProductCard: React.FunctionComponent<Props> = (props: Props) => {
  const [overlayVisible, setOverlayVisible] = useState<boolean>(false);
  const [overlayOverride, setOverlayOverride] = useState<boolean>(false);

  const getOverlayVisibility = () => {
    return overlayOverride ? true : overlayVisible;
  };

  const smallScreen = useMediaQuery({ query: "screen and (max-width: 768px)" });

  return (
    <div
      className={styles.container}
      onMouseEnter={smallScreen ? undefined : () => setOverlayVisible(true)}
      onMouseLeave={smallScreen ? undefined : () => setOverlayVisible(false)}
      onFocus={() => setOverlayOverride(!overlayOverride)}
      onClick={smallScreen ? () => setOverlayVisible(!overlayVisible) : undefined}
    >
      <img
        src="/assets/images/productlines/cleansing/decorations/decorations-left.svg"
        alt="P"
        className={styles.decoration}
        style={{
          left: "-60px",
          transformOrigin: "right",
          transform: getOverlayVisibility() ? "scale(1, 1)" : "scale(0, 1)",
        }}
      />
      <img
        src="/assets/images/productlines/cleansing/decorations/decorations-right.svg"
        alt=""
        className={styles.decoration}
        style={{
          right: "-60px",
          transformOrigin: "left",
          transform: getOverlayVisibility() ? "scale(1, 1)" : "scale(0, 1)",
        }}
      />
      <p className={styles.productTitle}>{props.title}</p>
      <img
        src={props.imgSrc}
        alt={props.title}
        className={styles.productImage}
      />
      <div
        className={styles.descriptionOverlay}
        style={{
          transform: getOverlayVisibility() ? "scale(1, 1)" : "scale(1, 0)",
        }}
      >
        <div className={styles.descriptionOverlayForeground}></div>
        <p className={styles.productTitle} style={{ color: "white" }}>
          {props.title}
        </p>
        <div className={styles.scrollContainer}>
          <div className={styles.descriptionText}>{props.description}</div>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
