import { FunctionComponent } from "react";
import styles from "./ProductsMenu.module.css";
import ProductsMenuPicture from "./ProductsMenuPicture";

const ProductsMenu: FunctionComponent = () => {
  return (
    <div className={styles.container}>
      <div className={styles.menuSection}>
        <h1>ЛИНЕЙКИ ПРОДУКТОВ</h1>
        <ul className={styles.optionList}>
          <li>
            <a href="/productlines/cleansing">Cleansing</a>
          </li>
          <li>
            <a href="/productlines/aqua-clear-skin">Aqua Clear Skin</a>
          </li>
          <li>
            <a href="/productlines/beauty-elixir">Beauty Elixir</a>
          </li>
          <li>
            <a href="/productlines/sheet-masks">Sheet Masks</a>
          </li>
          <li>
            <a href="/productlines/hyper-sensitive">Hyper Sensitive</a>
          </li>
          <li>
            <a href="/productlines/herbal-sensitive">Herbal Sensitive</a>
          </li>
          <li>
            <a href="/productlines/aqua-benefits">Aqua Benefits</a>
          </li>
          <li>
            <a href="/productlines/illuminating-pearl">Illuminating Pearl</a>
          </li>
          <li>
            <a href="/productlines/deep-moist-depot">Deep Moist Depot</a>
          </li>
          <li>
            <a href="/productlines/kissed-by-a-rose">Kissed by a Rose</a>
          </li>
          <li>
            <a href="/productlines/special-active">Special Active</a>
          </li>
          <li>
            <a href="/productlines/caviar-and-gold">Caviar and Gold</a>
          </li>
          <li>
            <a href="/productlines/daily-vitamins">Daily Vitamins</a>
          </li>
          <li>
            <a href="/productlines/body">Body</a>
          </li>
          <li>
            <a href="/productlines/foundations">Foundations</a>
          </li>
        </ul>
      </div>
      <div className={styles.menuSection}></div>
    </div>
  );
};

export default ProductsMenu;
