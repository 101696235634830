import ProductCard from "../../components/ProductCard";
import ProductGrid from "../../components/ProductGrid";
import ProductLinePageTitle from "../../components/ProductLinePageTitle";
import ProductLinePoster from "../../components/ProductLinePoster";
import Divider from "../../components/ui/Divider";
import WaveDivider from "../../components/ui/WaveDivider";
import styles from "./CleansingPage.module.css";

interface Props {}

const CleansingPage: React.FunctionComponent<Props> = (props: Props) => {
  return (
    <>
      <ProductLinePageTitle title="Cleansing" />
      <ProductLinePoster
        imgSrc="/assets/images/productlines/cleansing/poster.jpg"
        imgAlt="Cleansing"
      />
      <Divider />
      <div className={styles.container}>
        <div className={styles.title}>
          ГЛУБОКАЯ ОЧИСТКА ПОР
          <br />
          РУЧНАЯ РАБОТА!
        </div>
        <WaveDivider />
        <div className={styles.descriptionContainer}>
          <img
            className={styles.filler}
            src="/assets/images/productlines/cleansing/filler.jpg"
            alt="filler"
          />
          <div className={styles.description}>
            Красота нашей кожи начинается с ежедневного очищения в качестве
            основы основ для последующего ухода. С продуктами CLEANSING вы
            наслаждаетесь чистой свежестью в различных текстурах невесомая
            пенка, нежнейшее молочко, освежающие тоники. Кожа чувствует себя
            нежной, свежей и оптимально подготовленной к приему последующих
            косметических процедур.
            <br />
            Ежедневное тщательное очищение препаратами линии CLEANSING нежно и
            легко очищает кожу от остатков макияжа, пыли, грязи, кожного сала и
            роговых чешуек, и подготавливает кожу к последующему уходу.
          </div>
        </div>
        <WaveDivider />
        <ProductGrid>
          <ProductCard
            title="СКРАБ ДЛЯ ЛИЦА ДЕЛИКАТНЫЙ"
            description="Пилинг для лица с гранулами бамбука и жожоба мягко и бережно очищает кожу от
          омертвевших чешуек и загрязнений, стимулирует микроциркуляцию, делая кожу гладкой и
          эластичной. МАСЛО СЕМЯН ЛИМНАНТЕСА стабилизирует водный баланс кожи, питает
          и оставляет ощущение шелковисто-мягкой кожи. ГРАНУЛЫ ЖОЖОБА и БАМБУКА
          избавляют кожу от омертвевших клеток, «массирует» кожу и способствует ее
          микроциркуляции. ЭКСТРАКТ ВАСИЛЬКА освежает кожу, уменьшает легкие отеки и
          успокаивает раздражение и покраснение."
            imgSrc="/assets/images/productlines/cleansing/csm_Peeling_Neu_d503b2ea7c.png"
          />
          <ProductCard
            title="ТОНИК С AHA + BHA КИСЛОТАМИ"
            description="Тоник для лица с комплексом кислот AHA и BHA - это гарантия глубокого очищения и
          тонизации кожи! Тоник дарит коже чистоту и сияющий цвет. Омертвевшие клетки кожи
          эффективно удаляются, восстанавливается баланс ее увлажненности и устраняется
          проблема расширенных пор. Комплекс AHA –кислот состоит из гликолевой, молочной,
          лимонной и винной кислот. Благодаря различным молекулярным размерам комплекс
          действует как на поверхности кожи, так и в ее более глубоких слоях. Комплекс служит для
          удаления омертвевших клеток кожи, разглаживания сухой и огрубевшей кожи, легкого
          осветления пигментации и придания коже сияния. BHA - жирорастворимая салициловая
          кислота способна проникать в кожное сало в порах кожи, и таким образом очищать поры;
          оказывает антибактериальное и противовоспалительное действие . АЛОЭ ВЕРА и
          ЭКСТРАКТ ВАСИЛЬКА разглаживают сухую и шелушащуюся кожу, делают ее
          бархатистой и гладкой. Обладает противовоспалительными свойствами и снимает
          раздражение кожи."
            imgSrc="/assets/images/productlines/cleansing/Saeure_Toner_Neu.png"
          />
          <ProductCard
            title="ПАТЧИ ДЛЯ ДЕМАКИЯЖА ГЛАЗ"
            description="Безмасляные патчи для деликатного удаления макияжа с глаз (в том числе и водостойкая
          тушь), и одновременно, ухаживающие за ресницами. Оптимально подходят для
          чувствительных глаз и для применения людьми, использующими контактные линзы.
          ЭКСТРАКТ ЗАРОДЫШЕЙ ПШЕНИЦЫ ухаживает за кожей и способствует поддержанию
          ее эластичности."
            imgSrc="/assets/images/productlines/cleansing/csm_C_Augen-Make-Up-Entferner-Pads_Produktbild_klein_2_9ae40.png"
          />
          <ProductCard
            title="ТОНИК ДЕЛИКАТНЫЙ"
            description="Нежнейший тоник, без содержания спирта восстанавливает гидробаланс кожи. Устраняет
          покраснения, зуд и дискомфорт кожи. Легкий аромат оставляет приятное ощущение
          свежести кожи.
          ЭКСТРАКТ ВАСИЛЬКА освежает кожу, уменьшает легкие отечность и успокаивает
          раздражение и покраснение. ЭКСТРАКТ КОРНЯ СОЛОДКИ успокаивает, уменьшает
          раздражение и покраснение, защищает от воспалительных процессов кожи; повышает
          содержание влаги в коже."
            imgSrc="/assets/images/productlines/cleansing/csm_Mildes_Gesichtswasser_Neu_3cf8746dc4.png"
          />
          <ProductCard
            title="ПЕНКА ОЧИЩАЮЩАЯ"
            description="Воздушная пенка с нежнейшим ароматом подарит идеальную чистоту и свежесть коже.
          Идеально увлажняет кожу. Устраняет покраснения, зуд и дискомфорт кожи. ЭКСТРАКТ
          ВАСИЛЬКА освежает кожу, уменьшает легкие отеки и успокаивает раздражение и
          покраснение. АЛЛАНТОИН оказывает успокаивающее действие и устраняет раздражение
          кожи., активизирует обновление клеток и ускоряет ее регенерацию; обладает
          кератопластическим эффектом, а значит поры очищаются и кожное сало выделяется
          легче, тем самым эффективно противодействуют угрям и прыщам. ПИРОКТОН ОЛАМИН
          противодействует размножению бактерий. размягчает кожное сало."
            imgSrc="/assets/images/productlines/cleansing/csm_Schaum_Neu_1061d48b22.png"
          />
          <ProductCard
            title="МОЛОЧКО ОЧИЩАЮЩЕЕ"
            description="Нежное молочко бережно очищает кожу от загрязнений и остатков макияжа. Оказывает
          увлажняющее и разглаживающее действие. Устраняет покраснения, зуд и дискомфорт
          кожи. ЭКСТРАКТ ВАСИЛЬКА освежает кожу, уменьшает легкие отеки и успокаивает
          раздражение и покраснение. МАСЛО КОСТОЧЕК АБРИКОСА с высокой концентрацией
          линолевой кислоты, которая уменьшает тонкие линии, вызванные сухостью.
          ТЕРМАЛЬНАЯ Вода минерализует, увлажняет и освежает кожу."
            imgSrc="/assets/images/productlines/cleansing/csm_Milch_Neu_7d5f860352.png"
          />
        </ProductGrid>
      </div>
    </>
  );
};

export default CleansingPage;
