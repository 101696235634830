import styles from "./ProductLinePoster.module.css";

interface Props {
  imgSrc: string;
  imgAlt: string;
}

const ProductLinePoster: React.FunctionComponent<Props> = (props: Props) => {
  return (
    <img
      className={styles.posterImage}
      src={props.imgSrc}
      alt={props.imgAlt}
    />
  );
};

export default ProductLinePoster;
