const WaveDivider: React.FunctionComponent = () => {
  return (
    <img
      style={{
        textAlign: "center",
        marginTop: "1.15rem",
        boxSizing: "border-box",
        color: "#182160",
        maxWidth: "100%",
        height: "auto",
        verticalAlign: "middle",
        borderStyle: "none",
      }}
      src="/assets/images/wave.png"
      alt="Divider"
    />
  );
};

export default WaveDivider;
