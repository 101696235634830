import ProductCard from "../../components/ProductCard";
import ProductGrid from "../../components/ProductGrid";
import ProductLinePageTitle from "../../components/ProductLinePageTitle";
import ProductLinePoster from "../../components/ProductLinePoster";
import Divider from "../../components/ui/Divider";
import WaveDivider from "../../components/ui/WaveDivider";
import styles from "./Body.module.css";

interface Props { }

const BodyPage: React.FunctionComponent<Props> = (props: Props) => {
  return (
    <>
      <ProductLinePageTitle title="Body" />
      <ProductLinePoster
        imgSrc="/assets/images/productlines/body/body_poster.jpg"
        imgAlt="AquaClearSkin"
      />
      <Divider />
      <div className={styles.container}>
        <div className={styles.title}>
          МЯГКИЙ УХОД ДЛЯ НЕЖНОСТИ КОЖИ
        </div>
        <WaveDivider />
        <div className={styles.descriptionContainer}>
          <img
            className={styles.filler}
            src="/assets/images/productlines/body/body_filler.jpg"
            alt="filler"
          />
          <div className={styles.description}>
            SANS SOUCIS предлагает не только идеальный уход за лицом, но и комплексный уход за телом и разумом.
            Наша кожа - наш самый большой орган и поэтому нуждается в особом уходе. Слишком часто мы пренебрегаем кремом после душа.
            SANS SOUCIS серия  BODY ДЛЯ ОПТИМАЛЬНО УХОЖЕННОГО ТЕЛА:
            ЛОСЬОН БАРХАТНЫЙ для тела, ПИТАТЕЛЬНОЕ МОЛОЧКО  для тела и НЕЖНЫЙ ГЕЛЬ ДЛЯ ДУША обеспечивают идеальный уход с головы до ног.
          </div>
        </div>
        <WaveDivider />
        <ProductGrid>
          <ProductCard
            title="ПИТАТЕЛЬНОЕ МОЛОКО ДЛЯ ТЕЛА"
            description="Питательное молочко для тела с нежным фруктовым ароматом и увлажняющими ингредиентами: Термальная вода Баден-Бадена (богата минералами и микроэлементами) активизирует обмен веществ в коже и витализирует ее.
            Д-Пантенол - относится к группе витамина В. Пантенол оказывает положительное влияние на увлажненность кожи. Способствует регенерации клеток кожи и устранению раздражений. 
           Масло авокадо – увлажняет кожу , уменьшает морщины и оказывает противовоспалительное действие. 
           Масло зародышей пшеницы - содержит лецитин, большое количество ненасыщенных жирных кислот и витаминов, особенно витамина Е. Питает и разглаживает кожу и улучшает микроциркуляцию.
            Высокомолекулярная гиалуроновая кислота  – обволакивает кожу, как защитная пленка и предупреждает потерю влаги.
           "
            imgSrc="/assets/images/productlines/body/body_2.png"
          />
          <ProductCard
            title="БАРХАТИСТЫЙ ЛОСЬОН ДЛЯ ТЕЛА"
            description="Бархатный  лосьон для тела с комплексом AHA-кислот, маслом виноградных косточек и пантенола. Термальная вода Баден-Бадена (богата минералами и микроэлементами) активизирует обмен веществ в коже и витализирует ее.
            Лосьон содержит фруктовые кислоты (гликолевую, винную и лимонную), которые эффективно разглаживают сухие и огрубевшие участки кожи тела и придают здоровое сияние коже. Лосьон делает кожу бархатистой, нежной и гладкой. Быстро впитывается, не оставляя жирной пленки. Легкая текстура лосьона с нежным фруктовым ароматом оставляет ощущение легкости и свежести."
            imgSrc="/assets/images/productlines/body/body_1.png"
          />
          <ProductCard
            title="ГЕЛЬ ДЛЯ ДУША"
            description="Мягкий гель для душа нежно и тщательно очищает кожу, не пересушивая ее. Дарит бодрящую свежесть во время применения и после душа и обеспечивает прекрасное самочувствие. Фруктовый свежий аромат очаровывает. Гель для душа - идеальное начало дня, обеспечивает идеальную свежесть даже после занятий спортом. "
            imgSrc="/assets/images/productlines/body/body_3.png"
          />
        </ProductGrid>
      </div>
    </>
  );
};

export default BodyPage;