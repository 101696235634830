interface Props {
  title: string;
}

const ProductLinePageTitle: React.FunctionComponent<Props> = (props: Props) => {
  return (
    <div
      style={{
        fontSize: "2rem",
        fontWeight: "bold",
        lineHeight: "1.25",
        letterSpacing: "3px",
        textAlign: "center",
        textTransform: "uppercase",
        padding: "1rem 2.5rem 1rem 2.5rem",
        color: "#182160",
      }}
    >
      {props.title}
    </div>
  );
};

export default ProductLinePageTitle;
