import ProductCard from "../../components/ProductCard";
import ProductGrid from "../../components/ProductGrid";
import ProductLinePageTitle from "../../components/ProductLinePageTitle";
import ProductLinePoster from "../../components/ProductLinePoster";
import Divider from "../../components/ui/Divider";
import WaveDivider from "../../components/ui/WaveDivider";
import styles from "./HyperSensitive.module.css";

interface Props { }

const HyperSensitive: React.FunctionComponent<Props> = (props: Props) => {
  return (
    <>
      <ProductLinePageTitle title="Hyper Sensitive" />
      <ProductLinePoster
        imgSrc="/assets/images/productlines/hypersensitive/hyper_poster.jpg"
        imgAlt="Hyper"
      />
      <Divider />
      <div className={styles.container}>
        <div className={styles.title}>
          УКРЕПЛЕНИЕ ИММУНИТЕТА КОЖИ
          <br />
        </div>
        <WaveDivider />
        <div className={styles.descriptionContainer}>
          <img
            className={styles.filler}
            src="/assets/images/productlines/hypersensitive/hyper_filler.png"
            alt="filler"
          />
          <div className={styles.description}>
            Тысячи полезных бактерий находятся на поверхности нашей кожи, в том числе на лице, и составляют флору нашей кожи. Это защитный щит от неприятных патогенов и бактерий, которые способствуют воспалению. Если кожная флора вышла из равновесия, кожа более чувствительна, покраснела и стала сухой.
            <br />
            Чтобы вернуть чувствительной коже баланс, требуется множество хороших бактерий. Прекрасными помощниками являются пробиотики, которые укрепляют естественную иммунную систему нашей кожи, приводят в равновесие микрофлору кожи и снижают чувствительность кожи.
          </div>
        </div>
        <WaveDivider />
        <ProductGrid>
          <ProductCard
            title="КРЕМ 24-Ч С ПРОБИОТИКАМИ ДЛЯ ОЧЕНЬ ЧУВСТВИТЕЛЬНОЙ КОЖИ"
            description="Успокаивающий 24-часовой уход со специальным комплексом пробиотиков, маслом
            примулы вечерней и термальной водой укрепляет естественную иммунную систему кожи,
            помогает уменьшить жжение и зуд, устраняет покраснения и раздражения кожи. Крем
            стабилизирует водный баланс кожи. ПРОБИОТИКИ способны остановить развитие воспалительного процесса и
            дополнительно укрепить местный иммунитет, устраняя патогены. Пробиотики
            помогают улучшить выработку коллагена, эластина, способствуют сохранению влаги в
            клетках и оказывают мощный антиоксидантный эффект.
            «Живые» культуры в косметической продукции как никогда нужны коже для защиты от
            факторов окружающей среды. Ведь наши покровы страдают от неблагоприятной
            экологии, некачественной воды и небезопасных продуктов питания, агрессивных
            ультрафиолетовых лучей. Пробиотики помогают коже противостоять всем этим
            
            негативным факторам, косметика с особыми компонентами поддерживает здоровье
            кожных покровов."
            imgSrc="/assets/images/productlines/hypersensitive/hyper_sensitive1.png"
          />
          <ProductCard
            title="МАСКА С ПРОБИОТИКАМИ ДЛЯ ГИПЕР ЧУВСТВИТЕЛЬНОЙ КОЖИ"
            description="Инновации в уходе за кожей с помощью бактерий для естественного повышения
            иммунитета кожи. Исходя из любимого девиза дерматологов «помогать тем, кто помогает
            себе», мы вступаем в эру пробиотиков, или, скорее, в эру «пробиотической» косметики.
            ПРОБИОТИЧЕСКИЙ КОМПЛЕКС: Пробиотики - это неактивные (= не размножающиеся)
            микроорганизмы, которые положительно влияют на человеческий организм. Кожа
            выглядит более здоровой, прекрасно регенерирует и выглядит свежей, напитанной и
            тонизированной.
            МАСЛО ПРИМУЛЫ ВЕЧЕРНЕЙ обладает противовоспалительными свойствами и
            предотвращает потерю влаги. Масло восстанавливает кожный барьер и ухаживает за
            сухими, шелушащимися участками кожи, уменьшая зуд и покраснение."
            imgSrc="/assets/images/productlines/hypersensitive/hyper_sensitive2.png"
          />
        </ProductGrid>
      </div>
    </>
  );
};

export default HyperSensitive;