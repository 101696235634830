const Divider: React.FunctionComponent = () => {
  return (
    <div style={{
      height: '9px',
      background: "linear-gradient(to right, #182160, #2bbcd6)"
    }}/>
  );
};

export default Divider;
