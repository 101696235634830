import ProductCard from "../../components/ProductCard";
import ProductGrid from "../../components/ProductGrid";
import ProductLinePageTitle from "../../components/ProductLinePageTitle";
import ProductLinePoster from "../../components/ProductLinePoster";
import Divider from "../../components/ui/Divider";
import WaveDivider from "../../components/ui/WaveDivider";
import styles from "./Foundations.module.css";

interface Props { }

const Foundations: React.FunctionComponent<Props> = (props: Props) => {
  return (
    <>
      <ProductLinePageTitle title="Foundations" />
      <ProductLinePoster
        imgSrc="/assets/images/productlines/foundations/foundations_poster.jpg"
        imgAlt="AquaClearSkin"
      />
      <Divider />
      <div className={styles.container}>
        <div className={styles.title}>
          ВОЛШЕБСТВО ПРЕОБРАЖЕНИЯ ОДНИМ КАСАНИЕМ
        </div>
        <WaveDivider />
        <div className={styles.descriptionContainer}>
          <img
            className={styles.filler}
            src="/assets/images/productlines/foundations/foundations_filler.png"
            alt="filler"
          />
          <div className={styles.description}>
            Давайте представим: наши тональные средства будущего, которые не просто создают совершенный цвет лица, но объединяют несколько технологий в одну формулу и, таким образом, обеспечивают гибридную силу ухода.
            <br />
            Гениальные мульти-таланты отличаются изысканными текстурами и сложным тройным эффектом:
            Безупречный тон COLOR,  интенсивный уход CARE и совершенная двойная защита PROTECTION.
            <br />
            МАКИЯЖ + УХОД + ЗАЩИТА
            <br />
            3 уникальных текстуры практически сливаются с кожей при нанесении, колдуют безупречный цвет лица и идеально адаптированы к различным типам кожи и потребностям ухода.
          </div>
        </div>
        <WaveDivider />
        <ProductGrid>
          <ProductCard
            title="ФЛЮИД С ЛИФТИНГ ЭФФЕКТОМ"
            description="Антивозрастной  тонирующий флюид – лифтинг содержит комплекс Dermaxyl, стимулирующий клеточный обмен в коже,  протеины пшеницы помогут коже стать вновь подтянутой и скрыть морщинки. 
            Флюид , обогащенный витамином Е, термальной водой, маслом сердечника лугового  питают и ухаживают за кожей. Содержит солнцезащитный фактор  SPF 15. 
            Смягчает  линии и морщины,
            Оказывает подтягивающий эффект,
            Стимулирует обновление кожи,
            Защищает от свободных радикалов и ультрафиолетовых лучей.
            Рекомендовано: для нормальной, смешанной и сухой и/или зрелой   кожи. Нанесите средство  равномерно  губкой или пальцами и мягко растушуйте. Представлен в 3 тонах: 10, 30, 40
            "
            imgSrc="/assets/images/productlines/foundations/foundations_2.png"
          />
          <ProductCard
            title="КЛЕТОЧНЫЙ УВЛАЖНЯЮЩИЙ ФЛЮИД С ГИАЛУРОНОВОЙ КИСЛОТОЙ SPF 15"
            description="Идеальное средство нежной текстуры, сочетает в себе свойства и декоративной, и косметики по уходу за кожей. 
            Шелковистая структура тонального флюида, как бы сливается с кожей в единое целое!
            Увлажняющий тональный  флюид богат  активными компонентами такими как: гиалуроновая кислота, термальная вода, комплекс Fucogel®, что позволяет подарить даже самой сухой  коже длительное оптимальное увлажнение. Кроме того, входящие в состав микрочастицы слюды позволят  скрыть мелкие морщинки и подарят ровный тон коже. Содержит солнцезащитный фильтр SPF 15.
            Повышается уровень увлажнения кожи,
            Улучшает упругость кожи,
            Защищает кожу от потери влаги,
            Защищает от свободных радикалов и ультрафиолетовых лучей.
             Рекомендовано: для жирной, комбинированной и нормальной  кожи. Нанесите средство  равномерно  губкой или пальцами и мягко растушуйте. Представлен в 3 тонах: 10, 30, 40"
            imgSrc="/assets/images/productlines/foundations/foundations_1.png"
          />
          <ProductCard
            title="МАТИРУЮЩИЙ ФЛЮИД SPF 10"
            description="Бархатистый, легко наносимый   Матирующий флюид   не создает эффекта маски, практически сливаясь с кожей, придает ровный, матовый тон. Содержит витамин Е,  гиалуроновую кислоту и солнцезащитный фактор  SPF 15. Ниацинамид поддерживает снижение выработки кожного сала и размера пор, алоэ вера увлажняет и успокаивает легкое раздражение и покраснение.
            Регулирует выделение кожного сала.
            Выглядит тонким и матирующим, 
            Ровная матовая текстура,
            Защищает от свободных радикалов и ультрафиолетовых лучей.
            Рекомендовано: для жирной, комбинированной и нормальной  кожи. Нанесите средство  равномерно  губкой или пальцами и мягко растушуйте. Представлен в 3 тонах: 10, 20, 30"
            imgSrc="/assets/images/productlines/foundations/foundations_3.png"
          />
        </ProductGrid>
      </div>
    </>
  );
};

export default Foundations;