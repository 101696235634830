import ProductCard from "../../components/ProductCard";
import ProductGrid from "../../components/ProductGrid";
import ProductLinePageTitle from "../../components/ProductLinePageTitle";
import ProductLinePoster from "../../components/ProductLinePoster";
import Divider from "../../components/ui/Divider";
import WaveDivider from "../../components/ui/WaveDivider";
import styles from "./IlluminatingPearl.module.css";

interface Props { }

const IlluminatingPearl: React.FunctionComponent<Props> = (props: Props) => {
  return (
    <>
      <ProductLinePageTitle title="Illuminating Pearl" />
      <ProductLinePoster
        imgSrc="/assets/images/productlines/illuminatingpearl/pearl_poster.jpg"
        imgAlt="Illuminating Pearl"
      />
      <Divider />
      <div className={styles.container}>
        <div className={styles.title}>
          ЕСТЕСТВЕННОЕ СИЯНИЕ В КАЖДЫЙ МОМЕНТ
        </div>
        <WaveDivider />
        <div className={styles.descriptionContainer}>
          <img
            className={styles.filler}
            src="/assets/images/productlines/illuminatingpearl/pearl_filler.png"
            alt="filler"
          />
          <div className={styles.description}>
            Округлые, переливающиеся и умопомрачительно красивые жемчужины со дна океана завоевали сердца женщин всего мира. Сегодня мы делаем доступной элегантный жемчужный уход для вашей кожи, что позволит выглядеть роскошно! В нашей косметике только высококачественный жемчуг «Акоя», культивируемый в Увадзиме (Япония) считается настоящим жемчугом и более ценен, чем пресноводный жемчуг.

          </div>
        </div>
        <WaveDivider />
        <ProductGrid>
          <ProductCard
            title="КРЕМ ПИТАТЕЛЬНЫЙ ПЕРЛАМУТРОВОЕ СИЯНИЕ КОЖИ 24Ч УХОДА"
            description="Укрепляющий 24-часовой уход придает коже молодое сияние, обеспечивает пролонгированный лифтинг- эффект и предотвращает образование пигментных пятен. ПЕПТИДНЫЙ КОМПЛЕКС MATRIXYL, гексапептиды стимулируют выработку эластина и коллагена в коже, уменьшает тонкие линии и морщины, усиливает яркость кожи. 
            ЖЕМЧУЖНЫЙ ПРОТЕИН добывается из жемчужин, выращенных в Увадзиме (Япония). Увадзима считается одной из самых известных жемчужных ферм в Японии и, благодаря многолетнему опыту, предлагает высокий стандарт качества. Выращенные «акойские жемчужины» считаются настоящими жемчужинами и, в отличие от пресноводных , гораздо ценнее, что можно заметить и по блеску жемчуга. Жемчужный белок способствует естественному сиянию кожи и уменьшает линии и морщины,  способствует выработке эластина и тем самым сохраняет эластичность кожи. Кроме того, жемчужный белок эффективно предотвращает чрезмерное пигментирование, вызванное ультрафиолетовыми лучами, улучшает цвет лица.
            "
            imgSrc="/assets/images/productlines/illuminatingpearl/pearl_1.png"
          />
          <ProductCard
            title="КРЕМ ДЛЯ ОБЛАСТИ ШЕИ И ДЕКОЛЬТЕ"
            description="Высокоэффективный уход  за шеей и декольте с помощью многофункциональной  композиции из жемчужного белка, пептидов и термальной воды. Крем подтягивает кожу в области шеи и декольте с мгновенным и длительным эффектом. Кожа увлажнена, морщины разглаживаются, светоотражающие пигменты дарят красивое сияние, а ценные масла способствуют регенерации. Кожа мгновенно выглядит более гладкой,  упругой, увлажненной и молодой.
            "
            imgSrc="/assets/images/productlines/illuminatingpearl/pearl_2.png"
          />
          <ProductCard
            title="КРЕМ 24Ч УХОДА ДЛЯ НОРМАЛЬНОЙ КОЖИ"
            description="Укрепляющий 24-часовой уход придает коже молодое сияние, обеспечивает пролонгированный лифтинг- эффект и предотвращает образование пигментных пятен. ПЕПТИДНЫЙ КОМПЛЕКС  MATRIXYL, гексапептиды стимулируют выработку эластина и коллагена в коже, уменьшает тонкие линии и морщины, усиливает яркость кожи. 
            ЖЕМЧУЖНЫЙ ПРОТЕИН добывается из жемчужин, выращенных в Увадзиме (Япония). Увадзима считается одной из самых известных жемчужных ферм в Японии и, благодаря многолетнему опыту, предлагает высокий стандарт качества. Выращенные «акойские жемчужины» считаются настоящими жемчужинами и, в отличие от пресноводных , гораздо ценнее, что можно заметить и по блеску жемчуга. Белок способствует естественному сиянию кожи и уменьшает линии и морщины,  способствует выработке эластина и тем самым сохраняет эластичность кожи. Кроме того, жемчужный белок эффективно предотвращает чрезмерное пигментирование, вызванное ультрафиолетовыми лучами, улучшает цвет лица.
            "
            imgSrc="/assets/images/productlines/illuminatingpearl/pearl_3.png"
          />
          <ProductCard
            title="КРЕМ АНТИВОЗРАСТНОЙ ДЛЯ ГЛАЗ"
            description="Уплотняющий, подтягивающий крем для области вокруг глаз. Охлаждающий металлический аппликатор в сочетании с кофеином и  эсцином , входящих в состав,  снимают отечность, дарят коже свежесть и привлекательность.  Крем разглаживает мимические морщинки , а также устраняет темные круги. 
            ПЕПТИДНЫЙ КОМПЛЕКС MATRIXYL, гексапептиды стимулируют выработку эластина и коллагена в коже, уменьшает тонкие линии и морщины, усиливает яркость кожи. 
            ЖЕМЧУЖНЫЙ ПРОТЕИН добывается из жемчужин, выращенных в Увадзиме (Япония). Увадзима считается одной из самых известных жемчужных ферм в Японии и, благодаря многолетнему опыту, предлагает высокий стандарт качества. Выращенные «акойские жемчужины» считаются настоящими жемчужинами и, в отличие от пресноводных , гораздо ценнее, что можно заметить и по блеску жемчуга. Жемчужный белок способствует естественному сиянию кожи и уменьшает линии и морщины,  способствует выработке эластина и тем самым сохраняет эластичность кожи. Кроме того, жемчужный белок эффективно предотвращает чрезмерное пигментирование, вызванное ультрафиолетовыми лучами, улучшает цвет лица.
            "
            imgSrc="/assets/images/productlines/illuminatingpearl/pearl_4.png"
          />
        </ProductGrid>
      </div>
    </>
  );
};

export default IlluminatingPearl;