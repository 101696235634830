import { FunctionComponent } from "react";
import Slideshow from "../components/Slideshow";
import styles from "./Home.module.css";
import WaveDivider from "../components/ui/WaveDivider";

const Home: FunctionComponent = () => {
  return (
    <div className={styles.container}>
      <Slideshow />
      <h1 className={styles.heading}>
        О НАС
      </h1>
      <WaveDivider />
      <h2 className={styles.subheading}>
        Профессиональная косметика из Германии <br /> Эксклюзивный дистрибьютор
        в России и Белоруссии
      </h2>
      <span className={styles.aboutTextContainer}>
        Добро пожаловать в удивительный мир SANS SOUCIS - мир натуральной
        косметики, пробуждающий Вашу естественную красоту! Компания SANS SOUCIS
        находится во всемирно известном курортном городе Баден-Баден в предгорье
        Шварцвальда, постоянно совершенствуясь, идя в ногу со временем и даря
        Вам все самое лучшее.
      </span>
      <span className={styles.aboutTextContainer}>
        В 1939 году, более чем 70 лет назад, основатель компании Вальтер Фридман
        разработал уникальные препараты на основе экологически чистых
        растительных ингредиентов, выращиваемых на собственных плантациях.
        При разработке и производстве препаратов требовательный Вальтер Фридман
        делал упор на натуральность ингредиентов и их максимальную совместимость
        с кожей.
      </span>
      <div className={styles.picSection}>
        <img alt="Baden Baden" src="/assets/images/thermalwasser.jpg" />
        <span className={styles.aboutTextContainer}>
          Сердцем всех продуктов SANS SOUCIS является эксклюзивная термальная
          вода Баден-Бадена. Кожа идеально увлажняется и реминерализуется этим
          эликсиром красоты. Положительный эффект объясняется его минеральным
          составом, смесью мощных ингредиентов, действующих как энергетический
          бустер.
        </span>
      </div>
      <div className={styles.picSection}>
        <span className={styles.aboutTextContainer} style={{textAlign: "right"}}>
          Термальная вода мягко стимулирует метаболизм кожи, пробуждает
          ее жизненную силу и энергию, поддерживая ее в идеальной форме. С
          1997 г компания SANS SOUCIS имеет эксклюзивное право на использование
          богатой минералами термальной воды Баден-Бадена, которая и
          используется с того времени во всех препаратах SANS SOUCIS.
        </span>
        <img alt="Baden Baden" src="/assets/images/thermalwasser2.jpg" />
      </div>
      <div className={styles.picSection}>
        <img alt="Baden Baden" src="/assets/images/thermalwasser3.jpg" />
        <span className={styles.aboutTextContainer}>
        Согласно рейтингу немецкой ассоциации туризма и СПА (за 2005г)
        термальная вода из Баден-Бадена классифицирована как лечебная.
        Источник ее находится на глубине 300 м, в Северо-Восточной части города
        Баден-Баден и официально классифицируется как термальный источник,
        поскольку температура его достигает 65℃.
        </span>
      </div>
      <span className={styles.aboutTextContainer}>
        Сегодня SANS SOUCIS успешно сочетает натуральность и достижения науки в
        своих препаратах, неустанно проводит новые исследования и постоянно
        интегрирует свои достижения в выпускаемой продукции, что позволяет нам
        оставаться одним из ведущих производителей косметики уже почти 85 лет!
      </span>
      <WaveDivider />
      <h2 className={styles.subheading}>
        10 лет наша компания "Интер Гринлайн" успешно представляет SANS
        SOUCIS в России
      </h2>
      <span className={styles.aboutTextContainer}>
        Вся косметическая продукция, реализуемая компанией "Интер Гринлайн",
        прошла проверку и соответствует требованиям технического регламента "О
        безопасности парфюмерно-косметической продукции" и может быть реализована на Российском рынке.
      </span>
      <span className={styles.aboutTextContainer}>
        Компания "Интер Гринлайн" имеет широкую субдистрибьюторскую сеть во
        многих регионах нашей страны, представлена на известных маркетплэйсах
        OZON и WILDBERRIES.
      </span>
      <span className={styles.aboutTextContainer}>
        С каждым годом растет число наших клиентов, отдающих предпочтение
        натуральным ингредиентам и составам, максимально ухаживающим за нашей
        кожей.
      </span>
      <span className={styles.aboutTextContainer}>
        Косметика SANS SOUCIS позволяет надолго сохранить молодость и красоту кожи!
      </span>
      <span className={styles.aboutTextContainer}>
        Мы уверены, что и Вы по достоинству оцените слова "made in
        Germany", означающие гарантию высокого класса ингредиентов, качества
        и результата!
      </span>
    </div>
  );
};

export default Home;
