import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Layout from "./components/Layout";
import Home from "./pages/Home";
import AquaBenefits from "./pages/productlines/AquaBenefits";
import AquaClearSkin from "./pages/productlines/AquaClearSkin";
import BeautyElixir from "./pages/productlines/BeautyElixir";
import CleansingPage from "./pages/productlines/CleansingPage";
import DeepMoistDepot from "./pages/productlines/DeepMoistDepot";
import IlluminatingPearl from "./pages/productlines/IlluminatingPearl";
import HerbalSensitive from "./pages/productlines/HerbalSensitive";
import HyperSensitive from "./pages/productlines/HyperSensitive";
import SheetMasks from "./pages/productlines/SheetMasks";
import KissedByARose from "./pages/productlines/KissedByARose";
import SpecialActive from "./pages/productlines/SpecialActive";
import CaviarGold from "./pages/productlines/CaviarGold";
import DailyVitamins from "./pages/productlines/DailyVitamins";
import BodyPage from "./pages/productlines/Body";
import Foundations from "./pages/productlines/Foundations";
import Thermal from "./pages/productlines/Thermal";
import { useEffect } from "react";

function App() {
  return (
    <Layout>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/productlines/cleansing" element={<CleansingPage />} />
          <Route path="/productlines/aqua-clear-skin" element={<AquaClearSkin />} />
          <Route path="/productlines/beauty-elixir" element={<BeautyElixir />} />
          <Route path="/productlines/sheet-masks" element={<SheetMasks />} />
          <Route path="/productlines/hyper-sensitive" element={<HyperSensitive />} />
          <Route path="/productlines/herbal-sensitive" element={<HerbalSensitive />} />
          <Route path="/productlines/aqua-benefits" element={<AquaBenefits />} />
          <Route path="/productlines/illuminating-pearl" element={<IlluminatingPearl />} />
          <Route path="/productlines/deep-moist-depot" element={<DeepMoistDepot />} />
          <Route path="/productlines/kissed-by-a-rose" element={<KissedByARose />} />
          <Route path="/productlines/special-active" element={<SpecialActive />} />
          <Route path="/productlines/caviar-and-gold" element={<CaviarGold />} />
          <Route path="/productlines/daily-vitamins" element={<DailyVitamins />} />
          <Route path="/productlines/body" element={<BodyPage />} />
          <Route path="/productlines/foundations" element={<Foundations />} />
          <Route path="/thermal-spray" element={<Thermal />} />
        </Routes>
      </BrowserRouter>
    </Layout>
  );
}

export default App;
