import ProductCard from "../../components/ProductCard";
import ProductGrid from "../../components/ProductGrid";
import ProductLinePageTitle from "../../components/ProductLinePageTitle";
import ProductLinePoster from "../../components/ProductLinePoster";
import Divider from "../../components/ui/Divider";
import WaveDivider from "../../components/ui/WaveDivider";
import styles from "./HerbalSensitive.module.css";

interface Props { }

const HerbalSensitive: React.FunctionComponent<Props> = (props: Props) => {
  return (
    <>
      <ProductLinePageTitle title="Herbal Sensitive" />
      <ProductLinePoster
        imgSrc="/assets/images/productlines/herbalsensitive/herbal_poster.jpg"
        imgAlt="Herbal"
      />
      <Divider />
      <div className={styles.container}>
        <div className={styles.title}>
          УКРЕПЛЕНИЕ ИММУНИТЕТА КОЖИ
        </div>
        <WaveDivider />
        <div className={styles.descriptionContainer}>
          <img
            className={styles.filler}
            src="/assets/images/productlines/herbalsensitive/herbal_filler.png"
            alt="filler"
          />
          <div className={styles.description}>
            Оба продукта из серии HERBAL SENSITIVE, основанные на натуральных травах, повышают сопротивляемость кожи и обеспечивают ей богатый, ценный кондиционирующий уход. Тщательный подбор активных ингредиентов обещает мягкую стимуляцию кожи без дополнительного раздражения.
            СИЛА ПРИРОДНЫХ ТРАВ!
          </div>
        </div>
        <WaveDivider />
        <ProductGrid>
          <ProductCard
            title="ДНЕВНОЙ БАЛЬЗАМ ТРАВЯНОЙ ДЛЯ ЧУВСТВИТЕЛЬНОЙ СУХОЙ КОЖИ"
            description="Травяной дневной бальзам с комплексом растительных экстрактов и термальной воды ,
            отличается нежной текстурой и приятным растительным ароматом. Оптимально
            увлажняет кожу, восполняет нехватку собственных жирных кислот, необходимых для
            глубокого питания и увлажнения. Защищает и восстанавливает кожу от раздражений,
            МАСЛО ШИ питает и разглаживает кожу, делая ее бархатистой, мягкой и шелковистой,
            защищая от потери влаги и предупреждая сухость."
            imgSrc="/assets/images/productlines/herbalsensitive/herbal_sensitive1.png"
          />
          <ProductCard
            title="КРЕМ ТРАВЯННОЙ НОЧНОЙ УХОД ДЛЯ ЧУВСТВИТЕЛЬНОЙ, СУХОЙ
            КОЖИ"
            description="Травяной ночной крем с маслом зверобоя, экстрактом мелиссы и термальной водой
            глубоко увлажняет и успокаивает чувствительную кожу. Устраняет ощущения
            пощипывания и раздражения. Крем восстанавливает собственные защитные свойства
            кожи и чувствительная кожа обретает комфорт.
            Экстракт мелиссы содержит дубильные вещества, органические кислоты, витамины А,
            С, В1 и В2, фитонциды. Стимулирует обменные процессы в клетках кожи, успокаивает,
            уменьшает раздражения.
            Экстракт зверобоя оказывает противовоспалительное и антибактериальное действие. МАСЛО ШИ питает и разглаживает кожу, делая ее бархатистой, мягкой и шелковистой,
            защищая от потери влаги и предупреждая сухость."
            imgSrc="/assets/images/productlines/herbalsensitive/herbal_sensitive2.png"
          />
        </ProductGrid>
      </div>
    </>
  );
};

export default HerbalSensitive;