import { ReactNode } from "react";
import styles from "./ProductGrid.module.css";

interface Props {
  children: ReactNode[];
}

const ProductGrid: React.FunctionComponent<Props> = (props: Props) => {
  return <div className={styles.productGrid}>{props.children}</div>;
};

export default ProductGrid;
