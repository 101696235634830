import ProductCard from "../../components/ProductCard";
import ProductGrid from "../../components/ProductGrid";
import ProductLinePageTitle from "../../components/ProductLinePageTitle";
import ProductLinePoster from "../../components/ProductLinePoster";
import Divider from "../../components/ui/Divider";
import WaveDivider from "../../components/ui/WaveDivider";
import styles from "./AquaBenefits.module.css";

interface Props { }

const AquaBenefits: React.FunctionComponent<Props> = (props: Props) => {
  return (
    <>
      <ProductLinePageTitle title="Aqua Benefits" />
      <ProductLinePoster
        imgSrc="/assets/images/productlines/aquabenefits/benefits_poster.jpg"
        imgAlt="Aqua Benefits"
      />
      <Divider />
      <div className={styles.container}>
        <div className={styles.title}>
          ИДЕАЛЬНОЕ УВЛАЖНЕНИЕ ДЛЯ ВАШЕЙ КОЖИ
        </div>
        <WaveDivider />
        <div className={styles.descriptionContainer}>
          <img
            className={styles.filler}
            src="/assets/images/productlines/aquabenefits/benefits_filler.png"
            alt="filler"
          />
          <div className={styles.description}>
            AQUA BENEFITS - это универсальная серия по уходу за кожей, дарит увлажнение 24 часа в
            сутки, гарантируя оптимальный уровень увлажнения кожи. Идеально подобранные
            ингредиенты линии восстановят защитные барьерные функции кожи, предупреждая
            обезвоживание кожи, подарят отличный цвет лица.
            <br />
            Повышается уровень увлажненности кожи с пролонгированным эффектом.
            Мелкие морщинки заметно разглаживаются, кожа становится более свежей, плотной и упругой.
          </div>
        </div>
        <WaveDivider />
        <ProductGrid>
          <ProductCard
            title="КРЕМ ДЛЯ УХОДА ЗА КОЖЕЙ ЗОНЫ ВОКРУГ ГЛАЗ"
            description="Легкая, тающая текстура крема для контура глаз, обогащена маслом семян пассифлоры,
            глубоко и интенсивно увлажняет кожу, делая ее более гладкой. КОМПЛЕКС
            SESAFLASH ® - модифицированный кунжутный белок укрепляет, разглаживает линии и
            морщины кожи вокруг глаз, устраняет отечность.
            ГИАЛУРОНОВАЯ КИСЛОТА - считается лучшим гидродиспенсером среди экспертов по
            красоте. Она обеспечивает кожу ценной влагой в верхних слоях, поддерживает NMF
            (естественный увлажняющий фактор) и защищает кожу от потери влаги.
            ЭКСТРАКТ ВОДОРОСЛЕЙ богат полисахаридами и минералами, повышает водный
            баланс кожи и увлажняет ее вплоть до нижних слоев эпидермиса с длительным
            увлажняющим эффектом."
            imgSrc="/assets/images/productlines/aquabenefits/benefits_5.png"
          />
          <ProductCard
            title="ПИТАТЕЛЬНЫЙ КРЕМ 24Ч УХОДА ДЛЯ СУХОЙ КОЖИ"
            description="Крем для 24 – часового ухода разработан специально для сухой кожи. Глубоко питает
            и увлажняет сухую кожу, стирая следы усталости и наполняя энергией.
            ГИАЛУРОНОВАЯ КИСЛОТА - считается лучшим гидродиспенсером среди экспертов по
            красоте. Она обеспечивает кожу ценной влагой в верхних слоях, поддерживает NMF
            (естественный увлажняющий фактор) и защищает кожу от потери влаги.
            ЭКСТРАКТ ВОДОРОСЛЕЙ богат полисахаридами и минералами, повышает водный
            баланс кожи и увлажняет ее вплоть до нижних слоев эпидермиса с длительным
            увлажняющим эффектом.
            
            МАСЛО АВОКАДО обладает высоким содержанием ненасыщенных жирных кислот,
            которые делают кожу мягкой и эластичной. Масло также содержит витамины А, В, D, Е,
            лецитин и фитостеролы, которые укрепляют кожу и стимулируют образование новых
            клеток и замедляют процесс старения"
            imgSrc="/assets/images/productlines/aquabenefits/benefits_4.png"
          />
          <ProductCard
            title="КРЕМ 24Ч УХОДА ДЛЯ НОРМАЛЬНОЙ КОЖИ"
            description="Крем 24-часовой уход с гиалуроновой кислотой, экстрактом водорослей и термальной
            водой – это нежная, мягкая кожа и ощущение абсолютного комфорта мгновенно и на весь
            день. Интенсивно питает кожу, сокращает ощущение стянутости кожи и дискомфорта.
            Кожа преображается, вновь обретая красоту и ощущение абсолютного комфорта надолго.
            ГИАЛУРОНОВАЯ КИСЛОТА- считается лучшим гидродиспенсером среди экспертов по
            красоте. Она обеспечивает кожу ценной влагой в верхних слоях, поддерживает NMF
            (естественный увлажняющий фактор) и защищает кожу от потери влаги.
            
            ЭКСТРАКТ ВОДОРОСЛЕЙ богат полисахаридами и минералами, повышает водный
            баланс кожи и увлажняет ее вплоть до нижних слоев эпидермиса с длительным
            увлажняющим эффектом."
            imgSrc="/assets/images/productlines/aquabenefits/benefits_3.png"
          />
          <ProductCard
            title="24Ч КРЕМ-ГЕЛЬ, БЕЗ СОДЕРЖАНИЯ МАСЛА ДЛЯ КОЖИ С ДЕФИЦИТОМ
            ВЛАГИ"
            description="Крем-гель 24ч ухода с гиалуроновой кислотой, экстрактом водорослей и водой из
            термальных источников обеспечивает кожу ценной влагой и предотвращает появление
            мелких сухих складок и морщин.
            ГИАЛУРОНОВАЯ КИСЛОТА - считается лучшим гидродиспенсером среди экспертов по
            красоте. Она обеспечивает кожу ценной влагой в верхних слоях, поддерживает NMF
            (естественный увлажняющий фактор) и защищает кожу от потери влаги.
            ЭКСТРАКТ ВОДОРОСЛЕЙ богат полисахаридами и минералами, повышает водный
            баланс кожи и увлажняет ее вплоть до нижних слоев эпидермиса с длительным
            увлажняющим эффектом.
            КОМПЛЕКС ФУКОГЕЛЬ ® мгновенно увеличивает уровень влажности кожи до 79%,
            образует защитную, увлажняющую пленку на коже, минимизируя морщины и линии.
            
            СИНИЙ МАЛАХИТ защищает кожу от негативного воздействия свободных радикалов,
            поддерживает естественную защиту кожи и придает крему свежий синий цвет."
            imgSrc="/assets/images/productlines/aquabenefits/benefits_2.png"
          />
          <ProductCard
            title="МАСКА ДЛЯ КОЖИ С ДЕФИЦИТОМ ВЛАГИ"
            description="Гелевая маска для экстра-увлажнения кожи.  Предупреждает от потери влаги и придает коже бархатистость и мягкость. ГИАЛУРОНОВАЯ КИСЛОТА- считается лучшим гидродиспенсером среди экспертов по красоте. Она обеспечивает кожу ценной влагой в верхних слоях, поддерживает NMF  (естественный увлажняющий фактор) и защищает кожу  от потери влаги. ЭКСТРАКТ ВОДОРОСЛЕЙ богат полисахаридами и минералами, повышает водный баланс кожи и увлажняет ее вплоть до нижних слоев эпидермиса с длительным увлажняющим эффектом. ЭКСТРАКТ ПАРАКРЕССА тонизирует кожу и разглаживает мелкие морщинки. КОМПЛЕКС ФУКОГЕЛЬ ® мгновенно увеличивает уровень влажности кожи до 79%, образует защитную, увлажняющую пленку на коже, минимизируя морщины и линии. СИНИЙ МАЛАХИТ защищает кожу от негативного воздействия свободных радикалов, поддерживает естественную защиту кожи и придает маске изумительный синий цвет."
            imgSrc="/assets/images/productlines/aquabenefits/benefits_1.png"
          />
        </ProductGrid>
      </div>
    </>
  );
};

export default AquaBenefits;