import ProductCard from "../../components/ProductCard";
import ProductGrid from "../../components/ProductGrid";
import ProductLinePageTitle from "../../components/ProductLinePageTitle";
import ProductLinePoster from "../../components/ProductLinePoster";
import Divider from "../../components/ui/Divider";
import WaveDivider from "../../components/ui/WaveDivider";
import styles from "./SpecialActive.module.css";

// HUI dkjfnbslkdjfng
const SpecialActive: React.FunctionComponent = () => {
  return (
    <>
      <ProductLinePageTitle title="Special Active" />
      <ProductLinePoster
        imgSrc="/assets/images/productlines/specialactive/special_poster.jpg"
        imgAlt="SheetMasks"
      />
      <Divider />
      <div className={styles.container}>
        <div className={styles.title}>
          МОЩНЫЙ ИМПУЛЬС К РЕГЕНЕРАЦИИ КОЖИ
        </div>
        <WaveDivider />
        <div className={styles.descriptionContainer}>
          <img
            className={styles.filler}
            src="/assets/images/productlines/specialactive/special_filler.png"
            alt="filler"
          />
          <div className={styles.description}>
            Уже в возрасте 25 лет  начинается  естественный процесс старения. Стрессы, кондиционирование воздуха, неблагоприятное воздействие окружающей среды способны  ускорить этот процесс. И как результат: замедление клеточного деления, снижение  тонуса и эластичности, появление пигментных пятен, кожа выглядит усталой и тусклой. В этом вопросе природа-это наш вдохновитель! Линия ANTI AGE Special Active помогает коже раскрыть  свои ресурсы, делая ее заметно упругой и эластичной, выравнивает цвет и рельеф кожи, день за днем, делая ее более здоровой и отдохнувшей.
          </div>
        </div>
        <WaveDivider />
        <ProductGrid>
          <ProductCard
            title="АКТИВИЗИРУЮЩИЙ КРЕМ ДНЕВНОЙ"
            description="Крем компенсирует нехватку естественных кожи липидов, стимулирует выработку коллагена и эластина. 
            ГИАЛУРОНОВАЯ КИСЛОТА - считается лучшим гидродиспенсером среди экспертов по красоте. Она обеспечивает кожу ценной влагой в верхних слоях, поддерживает NMF  (естественный увлажняющий фактор) и защищает кожу  от потери влаги. восстанавливают гидролипидную мантию кожи. 
            Комплекс ВИТАМИНОВ  ДЛЯ ЗАЩИТЫ КОЖИ  способствует делению клеток и их здоровому росту, борется с чрезмерным ороговением. Укрепляется защитная функция кожи, поддерживается ее влагоудерживающая способность и эффективно защищается от свободных радикалов. Линии и морщины заметно уменьшаются. 
            МАСЛО АВОКАДО обладает высоким содержанием ненасыщенных жирных кислот, которые делают кожу мягкой и эластичной. Масло  также содержит витамины А, В, D, Е, лецитин и фитостеролы, которые укрепляют кожу и стимулируют образование новых клеток и замедляют процесс старения.
            "
            imgSrc="/assets/images/productlines/specialactive/special_1.png"
          />
          <ProductCard
            title="АКТИВИЗИРУЮЩИЙ КРЕМ НОЧНОЙ"
            description="Насыщенный ночной крем создан для восстановления и регенерации кожи. поддерживает сухую и гипер обезвоженную  кожу во время ночной фазы регенерации, укрепляет защитные функции кожи и уменьшает тонкие линии и морщины. Кожа обогащён  липидами, и  питает, увлажняет кожу, разглаживает морщины. Повышает плотность кожи, уменьшая глубину и протяженность морщин. Обеспечит активное восстановление кожи в течение ночи и лифтинг. 
            ГИАЛУРОНОВАЯ КИСЛОТА - считается лучшим гидродиспенсером среди экспертов по красоте. Она обеспечивает кожу ценной влагой в верхних слоях, поддерживает NMF  (естественный увлажняющий фактор) и защищает кожу  от потери влаги. восстанавливают гидролипидную мантию кожи. 
            Комплекс ВИТАМИНОВ  ДЛЯ ЗАЩИТЫ КОЖИ  способствует делению клеток и их здоровому росту, борется с чрезмерным ороговением. Укрепляется защитная функция кожи, поддерживается ее влагоудерживающая способность и эффективно защищается от свободных радикалов. Линии и морщины заметно уменьшаются. 
            МАСЛО АВОКАДО обладает высоким содержанием ненасыщенных жирных кислот, которые делают   кожу мягкой и эластичной. Масло  также содержит витамины А, В, D, Е, лецитин и фитостеролы, которые укрепляют кожу  и стимулируют образование новых клеток и замедляют процесс старения.
            "
            imgSrc="/assets/images/productlines/specialactive/special_2.png"
          />
          <ProductCard
            title="КРЕМ-АКТИВ ДЛЯ ГЛАЗ УКРЕПЛЯЮЩЕГО ДЕЙСТВИЯ"
            description="Крем эффективно питает и восстанавливает нежную кожу вокруг глаз. Обеспечивает оптимальное увлажнение и разглаживает мелкие морщинки. Уменьшает морщинки, вызванный сухостью кожи, защищает от обезвоживания и мгновенно расслабляет обезвоженную  кожу контуров глаз. Мимические линии и возрастные линии предотвращаются, уже существующие линии уменьшаются, а защитный барьер кожи укрепляется. 
            ГИАЛУРОНОВАЯ КИСЛОТА - считается лучшим гидродиспенсером среди экспертов по красоте. Она обеспечивает кожу ценной влагой в верхних слоях, поддерживает NMF  (естественный увлажняющий фактор) и защищает кожу  от потери влаги. восстанавливают гидролипидную мантию кожи.
             Комплекс ВИТАМИНОВ  ДЛЯ ЗАЩИТЫ КОЖИ  способствует делению клеток и их здоровому росту, борется с чрезмерным ороговением. Укрепляется защитная функция кожи, поддерживается ее влагоудерживающая способность и эффективно защищается от свободных радикалов. Линии и морщины заметно уменьшаются. 
            МАСЛО АВОКАДО обладает высоким содержанием ненасыщенных жирных кислот, которые делают   кожу мягкой и эластичной. Масло  также содержит витамины А, В, D, Е, лецитин и фитостеролы, которые укрепляют кожу  и стимулируют образование новых клеток и замедляют процесс старения.
            "
            imgSrc="/assets/images/productlines/specialactive/special_3.png"
          />
        </ProductGrid>
      </div>
    </>
  );
};

export default SpecialActive;