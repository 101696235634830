import { FunctionComponent, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronLeft,
  faCircle,
} from "@fortawesome/free-solid-svg-icons";
import styles from "./Slideshow.module.css";
import { useSwipeable } from "react-swipeable";

interface Slide {
  img: string;
  text?: string;
  link?: string;
  href?: string;
}

const Slideshow: FunctionComponent = () => {
  const [visibleSlide, setVisibleSlide] = useState(0);
  const [refreshTimeout, setRefreshTimeout] = useState<any>(null);
  const slides: Slide[] = [
    {
      img: "/assets/images/slides/1.jpg",
      text: "ЛИНЕЙКА CLEANSING: ГЛУБОКАЯ ОЧИСТКА ПОР",
      link: "> Узнать больше",
      href: "/productlines/cleansing",
    },
    {
      img: "/assets/images/slides/2.jpg",
      text: "ЛИНЕЙКА BEAUTY ELIXIR",
      link: "> Узнать больше о новинке",
      href: "/productlines/beauty-elixir",
    },
    // {
    //   img: "/assets/images/slides/3.png",
    // },
  ];

  // useEffect(() => {
  //   console.log("changed")
  //   refreshTimeout && clearTimeout(refreshTimeout)
  //   setRefreshTimeout(setTimeout(
  //     () => setVisibleSlide((visibleSlide + 1) % slides.length),
  //     5000
  //   ));
  // }, [visibleSlide, slides.length]);

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () =>
      setVisibleSlide(
        visibleSlide === 0 ? slides.length - 1 : visibleSlide - 1
      ),

    onSwipedRight: () => setVisibleSlide((visibleSlide + 1) % slides.length),
  });

  console.log("slide", visibleSlide);

  return (
    <div className={styles.slideshow} {...swipeHandlers}>
      {slides.map((slide, index) => (
        <div
          className={
            index === visibleSlide
              ? `${styles.slide} ${styles.visible}`
              : styles.slide
          }
        >
          <img className={styles.slideBackground} src={slide.img} alt="" />
          <div
            className={`${styles.slideTextContainer} ${styles.positionLeft}`}
          >
            <div className={styles.slideText}>
              {slide.text}
              <div className={styles.slideLink}>
                <a href={slide.href}>{slide.link}</a>
              </div>
            </div>
          </div>
        </div>
      ))}
      <div
        id={styles.slideshowPrev}
        onClick={() =>
          setVisibleSlide(
            visibleSlide === 0 ? slides.length - 1 : visibleSlide - 1
          )
        }
        className={styles.slideshowControl}
      >
        <FontAwesomeIcon icon={faChevronLeft} />
      </div>
      <div
        id={styles.slideshowNext}
        onClick={() => setVisibleSlide((visibleSlide + 1) % slides.length)}
        className={styles.slideshowControl}
      >
        <FontAwesomeIcon icon={faChevronRight} />
      </div>
      <div id={styles.slideshowIndicator}>
        {slides.map((slide, index) => {
          return (
            <div
              className={
                index === visibleSlide
                  ? `${styles.slideshowIndicatorDot} ${styles.active}`
                  : styles.slideshowIndicatorDot
              }
              onClick={() => {
                setVisibleSlide(index);
              }}
            >
              <FontAwesomeIcon icon={faCircle} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Slideshow;
