import ProductCard from "../../components/ProductCard";
import ProductGrid from "../../components/ProductGrid";
import ProductLinePageTitle from "../../components/ProductLinePageTitle";
import ProductLinePoster from "../../components/ProductLinePoster";
import Divider from "../../components/ui/Divider";
import WaveDivider from "../../components/ui/WaveDivider";
import styles from "./KissedByARose.module.css";

interface Props { }

const KissedByARose: React.FunctionComponent<Props> = (props: Props) => {
  return (
    <>
      <ProductLinePageTitle title="Kissed by a Rose" />
      <ProductLinePoster
        imgSrc="/assets/images/productlines/kissedbyarose/rose_poster.jpg"
        imgAlt="Aqua Benefits"
      />
      <Divider />
      <div className={styles.container}>
        <div className={styles.title}>
          БОДРЯЩИЙ ГЛОТОК ЖИЗНЕННЫХ СИЛ ДЛЯ КОЖИ!
        </div>
        <WaveDivider />
        <div className={styles.descriptionContainer}>
          <img
            className={styles.filler}
            src="/assets/images/productlines/kissedbyarose/rose_filler.png"
            alt="filler"
          />
          <div className={styles.description}>
          Срок жизни альпийской розы может достигать  ста лет! Это удивительное свойство  было взято для ежедневного ухода за лицом! SANS SOUCIS пользуется этим преимуществом и предлагает настоящие чудеса регенерации в уходе за кожей,  даря «заряд бодрости» коже благодаря  активным ингредиентам  линейки продуктов KISSED BY A ROSE д ля красоты и свежести  кожи.
          </div>
        </div>
        <WaveDivider />
        <ProductGrid>
          <ProductCard
            title="РОЗОВАЯ ВОДА СПРЕЙ ДЛЯ ЛИЦА"
            description="Бодрящий спрей розовой воды, с комплексом пребиотиков и термальной воды освежает кожу и укрепляет кожный барьер. Регулируют pH, оптимизируют уровень влажности и повышают жизненную энергию кожи
            РОЗОВАЯ ВОДА является истинным эликсиром красоты благодаря антиоксидантам и витаминам, содержащимся в розовой воде, кожа защищена от окислительного стресса. Это препятствует естественному старению кожи и поддерживает естественные восстанавливающие механизмы кожи. 
            ПРЕБИОТИКИ защищают микробиом  кожи,  естественный биологический барьер нашей кожи. Хорошие бактерии «пребиотики» отвечают за иммунитет, способствуют выработке церамидов, а также защищают от воспалений и пигментных пятен.
            "
            imgSrc="/assets/images/productlines/kissedbyarose/rose_1.png"
          />
          <ProductCard
            title="КРЕМ ВИТАЛИЗИРУЮЩИЙ ДЛЯ ГЛАЗ ВОССТАНАВЛИВАЮЩЕГО ДЕЙСТВИЯ"
            description="Восстанавливающий крем для глаз стабилизирует собственный репаративный потенциал  кожи, оказывает антиоксидантный и витаминизирующий эффект. 
            ЭКСТРАКТ СТВОЛОВЫХ КЛЕТОК АЛЬПИЙСКОЙ РОЗЫ способствует жизнедеятельности и функционированию стволовых клеток кожи, укрепляет защитный барьер и сопротивляемость кожи. Таким образом, стволовые клетки кожи защищены от воздействия окружающей среды и ультрафиолетового излучения. 
            REPAIR COMPLEX активирует и поддерживает естественные механизмы восстановления кожи. Противодействует преждевременному старению кожи, вызванному солнечным излучением и уменьшает морщины.
            "
            imgSrc="/assets/images/productlines/kissedbyarose/rose_2.png"
          />
          <ProductCard
            title="КРЕМ НОЧНОЙ ВОССТАНАВЛИВАЮЩЕГО ДЕЙСТВИЯ"
            description="Ночной крем интенсивно поддерживает процессы ночной регенерации кожи, повышает жизнеспособность ее стволовых клеток и уменьшает тонкие линии и морщины. Укрепляет естественные механизмы восстановления, поддерживает влажность кожи. 
            ЭКСТРАКТ СТВОЛОВЫХ КЛЕТОК АЛЬПИЙСКОЙ РОЗЫ  способствует жизнедеятельности и функционированию стволовых клеток кожи, укрепляет защитный барьер и сопротивляемость кожи. Таким образом, стволовые клетки кожи защищены от воздействия окружающей среды и ультрафиолетового излучения. 
            REPAIR COMPLEX активирует и поддерживает естественные механизмы восстановления кожи. Противодействует преждевременному старению кожи, вызванному солнечным излучением и уменьшает морщины. 
            "
            imgSrc="/assets/images/productlines/kissedbyarose/rose_3.png"
          />
          <ProductCard
            title="КРЕМ ДНЕВНОЙ ВОССТАНАВЛИВАЮЩЕГО ДЕЙСТВИЯ SPF 20"
            description="Нежный крем активного действия стабилизирует собственный репаративный потенциал  кожи, защитит кожу от негативного воздействи активной инсоляции. Масло косточек винограда окажет антиоксидантный и витаминизирующий эффект,  а высокоэффективные УФ-фильтры защитят кожу от UVA и UVB – лучей. 
            ЭКСТРАКТ СТВОЛОВЫХ КЛЕТОК АЛЬПИЙСКОЙ РОЗЫ  способствует жизнедеятельности и функционированию стволовых клеток кожи, укрепляет защитный барьер и сопротивляемость кожи. Таким образом, стволовые клетки кожи защищены от воздействия окружающей среды и ультрафиолетового излучения. 
            REPAIR COMPLEX активирует и поддерживает естественные механизмы восстановления кожи. Противодействует преждевременному старению кожи, вызванному солнечным излучением и уменьшает морщины.
            "
            imgSrc="/assets/images/productlines/kissedbyarose/rose_4.png"
          />
        </ProductGrid>
      </div>
    </>
  );
};

export default KissedByARose;