import { FunctionComponent } from "react";
import styles from "./DropDownMenuWrapper.module.css";

interface Props {
  children: React.ReactNode;
  smallScreen: boolean;
  active: boolean;
}

const DropDownMenu: FunctionComponent<Props> = (props: Props) => {
  return <div className={`${styles.dropdown} ${props.active ? styles.active : ""}`}>
    {props.children}
  </div>
}

export default DropDownMenu;