import ProductCard from "../../components/ProductCard";
import ProductGrid from "../../components/ProductGrid";
import ProductLinePageTitle from "../../components/ProductLinePageTitle";
import ProductLinePoster from "../../components/ProductLinePoster";
import Divider from "../../components/ui/Divider";
import WaveDivider from "../../components/ui/WaveDivider";
import styles from "./CaviarGold.module.css";

interface Props { }

const CaviarGold: React.FunctionComponent<Props> = (props: Props) => {
  return (
    <>
      <ProductLinePageTitle title="Caviar and Gold" />
      <ProductLinePoster
        imgSrc="/assets/images/productlines/caviarandgold/gold_poster.jpg"
        imgAlt="AquaClearSkin"
      />
      <Divider />
      <div className={styles.container}>
        <div className={styles.title}>
          КРАСОТА, КОТОРАЯ НЕ ПОДВЛАСТНА ВОЗРАСТУ!
        </div>
        <WaveDivider />
        <div className={styles.descriptionContainer}>
          <img
            className={styles.filler}
            src="/assets/images/productlines/caviarandgold/gold_filler.png"
            alt="filler"
          />
          <div className={styles.description}>
            На протяжении сотен лет икра, не только ослепляет вкусовые рецепторы, но и балует и питает кожу жизненно важными питательными веществами. Экстракт икры линии  Caviar & Gold настоящая роскошь с ярко выраженным  антивозрастным эффектом в сочетании с эффектом благополучия. 24K золотой порошок является драгоценностью с выдающимся анти-AGE эффектом и дарит роскошные моменты удовольствия и благополучия.
          </div>
        </div>
        <WaveDivider />
        <ProductGrid>
          <ProductCard
            title="КРЕМ-ЛЮКС АНТИВОЗРАСТНОЙ ИКРА И ЗОЛОТО 24Ч"
            description="Роскошный крем 24-часового ухода повышает  тонус и тургор кожи, ухаживает за кожей, требующей  особого ухода. Стволовые клетки арганы, термальная вода Баден-Бадена, гиалуроновая кислота, пептиды, экстракты икры гилоцериуса, масло ши, –  идеальный активный комплекс, который предотвращает преждевременное старение кожи, противодействует появлению морщин, смягчает и питает кожу. 
            ЭКСТРАКТ ЧЕРНОЙ ИКРЫ активизирует клеточный обмен кожи. Благодаря этому процессу уменьшаются линии и мелкие морщины, а  кожа защищена от воздействия светоиндуцированного старения. Повышается упругость и эластичность кожи. Экстракт черной икры выравнивает уровень увлажнения кожи. 
            24-Каратная ЗОЛОТАЯ ПУДРА придает коже уникальное сияние. 
            ДРАКОНИЙ ФРУКТ уменьшает внешние признаки старения и поддерживает упругость кожи; защищает и поддерживает естественные процессы кожи и успешно борется с потерей плотности кожи. 
            СТВОЛОВЫЕ КЛЕТКИ АРГАНЫ способствуют омоложению кожи, стимулируя рост стволовых клеток;  оказывает разглаживающее и укрепляющее действие, повышает  плотность и упругость кожи.
            "
            imgSrc="/assets/images/productlines/caviarandgold/gold_1.png"
          />
          <ProductCard
            title="КРЕМ-ЛЮКС АНТИВОЗРАСТНОЙ ИКРА И ЗОЛОТО ДЛЯ КОНТУРА ГЛАЗ"
            description="Уход за кожей контура глаз требует особого внимания. Новинка от Sans Soucis отлично справляется с этой задачей: предотвращает преждевременное старение кожи, противодействует появлению морщин, смягчает и питает кожу. 
            ЭКСТРАКТ ЧЕРНОЙ ИКРЫ активизирует клеточный обмен кожи. Благодаря этому процессу уменьшаются линии и мелкие морщины, а  кожа защищена от воздействия светоиндуцированного старения. Повышается упругость и эластичность кожи. Экстракт икры выравнивает уровень увлажнения кожи.
             24-Каратная ЗОЛОТАЯ ПУДРА придает коже уникальное сияние. 
            ДРАКОНИЙ ФРУКТ уменьшает внешние признаки старения и поддерживает упругость кожи; защищает и поддерживает естественные процессы кожи и успешно борется с потерей плотности кожи. 
            СТВОЛОВЫЕ КЛЕТКИ АРГАНЫ способствуют омоложению кожи, стимулируя рост стволовых клеток;  оказывает разглаживающее и укрепляющее действие, повышает  плотность и упругость кожи.
            "
            imgSrc="/assets/images/productlines/caviarandgold/gold_2.png"
          />
          <ProductCard
            title="ПИТАТЕЛЬНЫЙ КРЕМ-ЛЮКС АНТИВОЗРАСТНОЙ ИКРА И ЗОЛОТО 24Ч"
            description="Роскошный крем 24-часового ухода повышает  тонус и тургор кожи, а ухаживает за кожей, требующей  особого внимания. Стволовые клетки арганы, термальная вода Баден-Бадена, гиалуроновая кислота, пептиды, экстракты икры, гилоцериуса, масло ши, –  идеальный активный комплекс, который предотвращает преждевременное старение кожи, противодействует появлению морщин, смягчает и питает кожу
            ЭКСТРАКТ ЧЕРНОЙ ИКРЫ активизирует клеточный обмен кожи. Благодаря этому процессу уменьшаются линии и мелкие морщины, а  кожа защищена от воздействия светоиндуцированного старения. Повышается упругость и эластичность кожи. Экстракт икры выравнивает уровень увлажнения кожи. 
           24-Каратная ЗОЛОТАЯ ПУДРА придает коже уникальное сияние.
            ДРАКОНИЙ ФРУКТ уменьшает внешние признаки старения и поддерживает упругость кожи; защищает и поддерживает естественные процессы кожи и успешно борется с потерей плотности кожи. 
           СТВОЛОВЫЕ КЛЕТКИ АРГАНЫ способствуют омоложению кожи, стимулируя рост стволовых клеток;  оказывает разглаживающее и укрепляющее действие, повышает  плотность и упругость кожи. 
           "
            imgSrc="/assets/images/productlines/caviarandgold/gold_3.png"
          />
        </ProductGrid>
      </div>
    </>
  );
};

export default CaviarGold;