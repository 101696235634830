import ProductCard from "../../components/ProductCard";
import ProductGrid from "../../components/ProductGrid";
import ProductLinePageTitle from "../../components/ProductLinePageTitle";
import ProductLinePoster from "../../components/ProductLinePoster";
import Divider from "../../components/ui/Divider";
import WaveDivider from "../../components/ui/WaveDivider";
import styles from "./SheetMasks.module.css";

// HUI dkjfnbslkdjfng
const SheetMasks: React.FunctionComponent = () => {
  return (
    <>
      <ProductLinePageTitle title="Sheet Masks" />
      <ProductLinePoster
        imgSrc="/assets/images/productlines/sheetmasks/SheetMasksposter.jpg"
        imgAlt="SheetMasks"
      />
      <Divider />
      <div className={styles.container}>
        <div className={styles.title}>
          ДЛЯ КАЖДОГО ТИПА КОЖИ
        </div>
        <WaveDivider />
        <div className={styles.descriptionContainer}>
          <img
            className={styles.filler}
            src="/assets/images/productlines/sheetmasks/masks_filler.png"
            alt="filler"
          />
          <div className={styles.description}>
            Тканевые маски в моде – неудивительно! Они практичны и просты в использовании, дают быстрый эффект. Эти усилители красоты мгновенно придают коже свежий и жизненный вид и могут быть использованы для короткого перерыва от напряженной повседневной рутины. Довольно сложно иногда уделить время себе и отвлечься от повседневности.
          </div>
        </div>
        <WaveDivider />
        <ProductGrid>
          <ProductCard
            title="МАСКА-ЛИФТИНГ ЧЕРНЫЙ МАГНИТ"
            description="Абсолютный суперхит и настоящее немецкое качество! Маска для глубокого очищения и моментального увлажняющего эффекта. Экстракт магнолии и салициловая кислота в сочетании с древесным углем мягко очищают кожу от избытков кожного сала и загрязнений. При регулярном применении маска предотвращает появление черных точек и воспалений на коже, очищая и сужая поры. 
            ЭКСТРАКТ МАГНОЛИИ известен своими антимикробными свойствами и  устраняет  воспаления.
             САЛИЦИЛОВАЯ КИСЛОТА  борется с воспалением и гиперкератозом благодаря своему мягкому кератолитическому действию. 
            КОДИАВАЛЕН (ЗЕЛЕНЫЕ ВОДОРОСЛИ) богат минералами. Активно повышает способность кожи удерживать воду и защищает ее от вредных воздействий окружающей среды.
            "
            imgSrc="/assets/images/productlines/sheetmasks/mask1.png"
          />
          <ProductCard
            title="МАСКА-ЛИФТИНГ СУПЕРУВЛАЖНЕНИЕ"
            description="Суперувлажняющая  маска с 2-мя видами гиалуроновой кислоты  -  гиалурновая кислота и супергиалуроновая кислота! Со стволовыми клетками арганы, экстрактом икры, комплексом пептидов.  Моментально увлажняет, идеально подтягивает, дает пролонгированный результат. Абсолютный суперхит и настоящее немецкое качество! Высокая концентрация активных ингредиентов, Минимум времени, максимум результата. Нежная основа из натуральной целлюлозы идеально прилегает к коже лица. КОДИАВАЛЕН (ЗЕЛЕНЫЕ ВОДОРОСЛИ) богат минералами. Резко повышает способность кожи удерживать воду и защищает ее от вредных воздействий окружающей среды.  ГИАЛУРОНОВАЯ КИСЛОТА- считается лучшим гидродиспенсером среди экспертов по красоте. Она обеспечивает кожу ценной влагой в верхних слоях, поддерживает NMF  (естественный увлажняющий фактор) и защищает кожу  от потери влаги. СУПЕР ГИАЛУРОНОВАЯ КИСЛОТА (АЦЕТИЛИРОВАННЫЙ ГИАЛУРОНАТ НАТРИЯ) - низкомолекулярный вариант гиалуроновой кислоты, для более глубокого  увлажнения кожи."
            imgSrc="/assets/images/productlines/sheetmasks/mask2.png"
          />
          <ProductCard
            title="МАСКА-ЛИФТИНГ АНТИВОЗРАСТНАЯ"
            description="Антивозрастная маска со стволовыми клетками арганы и мыльнянки, гиалуроновой
            кислоты, комплексом пептидов,экстрактом планктона, эктсрактом овса! Моментально
            увлажняет, идеально подтягивает, дает пролонгированный результат. Абсолютный
            суперхит и настоящее немецкое качество! Высокая концентрация активных ингредиентов,
            минимум времени, максимум результат. Нежная основа из натуральной целлюлозы
            идеально прилегает к коже лица. АНТИВОЗРАСТНОЙ КОМПЛЕКС ПЕПТИДОВ
            улучшает эластичность и упругость кожи и считаются настоящим супер- средством против
            морщин. ЭКСТРАКТ РАСТИТЕЛЬНЫХ СТВОЛОВЫХ КЛЕТОК – комплекс экстрактов
            растительных стволовых клеток укрепляет и подтягивает зрелую кожу. ПЛАНКТОН
            стимулирует синтез гиалуроновой кислоты способствует обновлению клеток."
            imgSrc="/assets/images/productlines/sheetmasks/mask3.png"
          />
        </ProductGrid>
      </div>
    </>
  );
};

export default SheetMasks;