import ProductCard from "../../components/ProductCard";
import ProductGrid from "../../components/ProductGrid";
import ProductLinePageTitle from "../../components/ProductLinePageTitle";
import ProductLinePoster from "../../components/ProductLinePoster";
import Divider from "../../components/ui/Divider";
import WaveDivider from "../../components/ui/WaveDivider";
import styles from "./BeautyElixir.module.css";

interface Props { }

const BeautyElixir: React.FunctionComponent<Props> = (props: Props) => {
  return (
    <>
      <ProductLinePageTitle title="Beauty Elixir" />
      <ProductLinePoster
        imgSrc="/assets/images/productlines/beautyelixir/elixir_poster.jpg"
        imgAlt="BeautyElixir"
      />
      <Divider />
      <div className={styles.container}>
        <div className={styles.title}>
          BEAUTY-BOOSTER
        </div>
        <WaveDivider />
        <div className={styles.descriptionContainer}>
          <img
            className={styles.filler}
            src="/assets/images/productlines/beautyelixir/elixir_filler.png"
            alt="filler"
          />
          <div className={styles.description}>
            Эликсиры красоты позволяют свободно сочетать средства для улучшения цвета лица. Будь то юная красота, идеальный баланс, хорошо сбалансированная или всесторонне защищенная кожа, с высококонцентрированными, интенсивно действующими эликсирами каждое индивидуальное желание по уходу будет выполнено.
          </div>
        </div>
        <WaveDivider />
        <ProductGrid>
          <ProductCard
            title="СЫВОРОТКА С 10% НИАЦИНАМИДОМ"
            description="НИАЦИНАМИД также известен как витамин В3. Он может минимизировать пористость кожи,
            регулировать выработку кожного сала и, таким образом, снижать жирность кожи в долгосрочной
            перспективе. Благодаря противовоспалительным свойствам может успокаивать воспаленные и
            раздраженные участки кожи. Гиперпигментированные участки кожи могут быть уменьшены, а
            старение кожи предотвращено. Витамин В3 способствует удержанию влаги в коже и
            предотвращает ее потерю, что делает его идеальным активным ингредиентом для сухой и
            обезвоженной кожи."
            imgSrc="/assets/images/productlines/beautyelixir/Niacinamid_Serum.png"
          />
          <ProductCard
            title="АКТИВИЗИРУЮЩАЯ ЛИФТИНГ-СЫВОРОТКА"
            description="Сыворотка повышает упругость и тонус кожи. Оказывает мощное увлажняющее и антивозрастное действие. Комплекс пептидов  уменьшает линии и морщины, сохраняя молодость кожи. МАТРИКСИЛ и ГЕКСАПЕПТИДЫ-  способствуют выработке коллагена в коже, оптимизируют эластичность и предотвращают преждевременную потерю эластичности. ПЛАНКТОН стимулирует синтез гиалуроновой кислоты способствует  обновлению клеток. ГИАЛУРОНОВАЯ КИСЛОТА- считается лучшим гидродиспенсером среди экспертов по красоте. Она обеспечивает кожу ценной влагой в верхних слоях, поддерживает NMF  (естественный увлажняющий фактор) и защищает кожу  от потери влаги.
            "
            imgSrc="/assets/images/productlines/beautyelixir/aktiv_Uebersicht.png"
          />
          <ProductCard
            title="ГИАЛУРНОВАЯ СЫВОРОТКА ДЛЯ ВСЕХ ТИПОВ КОЖИ"
            description="Сыворотка с содержанием 2% гиалуроновой кислоты интенсивно увлажняет кожу
            снаружи и изнутри. Благодаря гиалуроновой кислоте 2-х видов (с длинной и короткой
            цепью), быстро впитывается в кожу, стимулирует выработку собственной гиалуроновой
            кислоты и повышает эластичность кожи. Сыворотка содержит ЭКСТРАКТ ОГУРЦА,
            который освежает и тонизирует кожу, обладает поросуживающим действием, устраняет
            покраснения и радражение кожи. ГИАЛУРОНОВАЯ КИСЛОТА обеспечивает кожу
            ценной влагой в верхних слоях кожи, поддерживает NMF (Естественный увлажняющий
            фактор) и тем самым спасает ее от потери влаги. Благодаря этому кожа приобретает
            эластичность и упругость."
            imgSrc="/assets/images/productlines/beautyelixir/Elixir-2_Hyaluronserum.png"
          />
          <ProductCard
            title="НОЧНАЯ МАСЛЯНАЯ СЫВОРОТКА С РЕТИНОЛОМ"
            description="Масляная ночная сыворотка с высокоэффективным сочетанием 0,3% ретинола, 0,3% бакухиола и ценных
            масел действует против более 100 проблем кожи, поддерживает процессы регенерации кожи и борется с
            признаками преждевременного старения кожи в течение ночи. Масляная сыворотка полностью не содержит
            парфюмированных отдушек и содержит 99% НАТУРАЛЬНЫХ ИНГРЕДИЕНТОВ. Ретинол - это высокоэффективный антивозрастной ингредиент. Ретинол способствует регенерации клеток
            кожи, заполнению морщин за счет стимулирования биосинтеза коллагена, а также оптимизации текстуры и
            упругости кожи."
            imgSrc="/assets/images/productlines/beautyelixir/Retinol_Uebersicht.png"
          />
          <ProductCard
            title="СЫВОРОТКА С КИСЛОТАМИ АНА+ВНА"
            description="Сыворотка с кислотами AHA + BHA выравнивает поверхность кожи и дарит коже сияние.
            Обладает умеренным эксфолиирующим, а так же хорошим бактерицидным,
            себостатическим, камедонолитическим, антиоксидантным и депигментирующим
            эффектом, нормализует процессы салоотделения и оказывает опосредованное
            увлажняющее действие. Содержит 97% натуральных ингредиентов."
            imgSrc="/assets/images/productlines/beautyelixir/Saeureserum-Uebersicht.png"
          />
          <ProductCard
            title="СОЛНЦЕЗАЩИТНАЯ СЫВОРОТКА С SPF 50"
            description="Сыворотка с защитой LSF/SPF 50 , Сыворотка с защитой UVA и UVB 50, алоэ вера и эктоин образуют мультизащитный комплекс для кожи и защищают ее от негативного воздействия солнечных лучей. Предупреждает фотоиндуцированное старение кожи и блокирует образование пигментных пятен.  
            Защищает кожу от негативного воздействия солнечных лучей,
            Защищает кожу от признаков преждевременного старения кожи,
            Защищает кожу от потери влаги.
            ЗАЩИТА ОТ УЛЬТРАФИОЛЕТОВОГО ИЗЛУЧЕНИЯ: Используемая система УФ-фильтрации защищает кожу от ультрафиолетового излучения. Он состоит из идеальной комбинации четырех различных химических светозащитных фильтров. Используемая система фильтрации не только удобна для кожи, но и может называться «reef save» сохранение рифа, т.е. не наносит вред природе при купании в море.
            ПРЕБИОТИЧЕСКИЙ КОМПЛЕКС:  являются частью иммунитета кожи, сохраняют целостность гидролипидной мантии, поддерживают оптимальный уровень увлажненности, обладают антиоксидантной активностью.
            "
            imgSrc="/assets/images/productlines/beautyelixir/Sun_Protection_Serum.png"
          />
        </ProductGrid>
      </div>
    </>
  );
};

export default BeautyElixir;