import { FunctionComponent, useState } from "react";
import styles from "./Layout.module.css";
import DropDownMenu from "./ui/DropDownMenuWrapper";
import Divider from "./ui/Divider";
import ProductsMenu from "./menus/ProductsMenu";
import { useMediaQuery } from "react-responsive";

interface Props {
  children: React.ReactNode;
}

interface NavItem {
  title: string;
  href: string;
  hoverMenuContent: React.ReactElement | null;
}

const Layout: FunctionComponent<Props> = (props: Props) => {
  const [responsiveNavVisible, setResponsiveNavVisible] = useState(false);
  const [activeDropDown, setActiveDropDown] = useState<number | null>(null);

  const smallScreen = useMediaQuery({
    query: "screen and (max-width: 768px)",
  });

  const nav: NavItem[] = [
    {
      title: "Продукция",
      href: "#",
      hoverMenuContent: <ProductsMenu />,
    },
    {
      title: "Термальный спрей",
      href: "/thermal-spray",
      hoverMenuContent: null,
    },
    {
      title: "О нас",
      href: "/",
      hoverMenuContent: null,
    },
    {
      title: "Магазин",
      href: "https://mag.sanssoucis.ru/",
      hoverMenuContent: null,
    },
  ];

  return (
    <div className={styles.container}>
      <div
        id={styles.darkenContent}
        className={responsiveNavVisible ? styles.active : ""}
        onClick={() => setResponsiveNavVisible(false)}
      ></div>
      <header>
        <a href="/" className={styles.logoContainer}>
          <img
            className={styles.logo}
            src="/assets/images/logo.svg"
            alt="Ein Schwanz" // ! remove this
            // alt="Sans Soucis"
          />
        </a>
        <div
          id={styles.burgerNav}
          onClick={() => setResponsiveNavVisible(true)}
        >
          <div className={styles.stripe}></div>
          <div className={styles.stripe}></div>
          <div className={styles.stripe}></div>
        </div>
        <div
          id={styles.nav}
          className={responsiveNavVisible ? styles.responsive : ""}
        >
          {nav.map((item, index) => (
            <a href={item.href}>
              <div
                className={
                  window.location.pathname === item.href
                    ? `${styles.navItem} ${styles.active}`
                    : styles.navItem
                }
                onMouseEnter={
                  smallScreen ? undefined : () => setActiveDropDown(index)
                }
                onMouseLeave={
                  smallScreen ? undefined : () => setActiveDropDown(null)
                }
                onClick={
                  smallScreen
                    ? () => {
                        setActiveDropDown(
                          activeDropDown === index ? null : index
                        );
                      }
                    : undefined
                }
              >
                <div className={styles.navTitle}>{item.title}</div>
                <div className={styles.shade} />
                {item.hoverMenuContent ? (
                  <DropDownMenu
                    smallScreen={smallScreen}
                    active={activeDropDown === index}
                  >
                    {item.hoverMenuContent}
                  </DropDownMenu>
                ) : null}
              </div>
            </a>
          ))}
        </div>
      </header>
      <Divider />
      {props.children}
      <footer>
        <div className={styles.footerContent}>
          <div className={styles.socials}>
            {/* <a href="https://www.instagram.com/sanssoucis_ru/">
            <img
              className={styles.socialLogo}
              src="/assets/images/instagram.svg"
              alt="Instagram"
            />
            </a> */}
            <a href="https://vk.com/sanssoucis_ru/">
              <img
                className={styles.socialLogo}
                src="/assets/images/vk.svg"
                alt="VK"
              />
            </a>
            <a href="https://t.me/sanssoucis_ru/">
              <img
                className={styles.socialLogo}
                src="/assets/images/telegram.svg"
                alt="Telegram"
              />
            </a>
          </div>
          <h4 style={{ textAlign: "center" }}>г. Москва, Кронштадтский бульвар, 7а, офис В 303</h4>
          <h4 style={{ textAlign: "center" }}>
            Представительство в Москве:
            <br />
            <a href="tel:+79255130455">+7 (925) 513-04-55</a>
            <br />
            <a href="tel:+79255130411">+7 (925) 513-04-11</a>
          </h4>
          <h4 style={{ textAlign: "center" }}>© 2023 | SANS SOUCIS | Эксклюзивный дистрибьютор в России ООО "Интер Гринлайн"</h4>
        </div>
      </footer>
    </div>
  );
};

export default Layout;
