import ProductCard from "../../components/ProductCard";
import ProductGrid from "../../components/ProductGrid";
import ProductLinePageTitle from "../../components/ProductLinePageTitle";
import ProductLinePoster from "../../components/ProductLinePoster";
import Divider from "../../components/ui/Divider";
import WaveDivider from "../../components/ui/WaveDivider";
import styles from "./DeepMoistDepot.module.css";

interface Props { }

const DeepMoistDepot: React.FunctionComponent<Props> = (props: Props) => {
  return (
    <>
      <ProductLinePageTitle title="Deep Moist Depot" />
      <ProductLinePoster
        imgSrc="/assets/images/productlines/deepmoistdepot/dmd_poster.jpg"
        imgAlt="Illuminating Pearl"
      />
      <Divider />
      <div className={styles.container}>
        <div className={styles.title}>
          ЛИНИЯ ЭКСТРА УВЛАЖНЕНИЯ

        </div>
        <WaveDivider />
        <div className={styles.descriptionContainer}>
          <img
            className={styles.filler}
            src="/assets/images/productlines/deepmoistdepot/dmd_filler.png"
            alt="filler"
          />
          <div className={styles.description}>
            Линия для депонирования , накопления и удержания  влаги в коже и «убийца» морщин: 24-часовое увлажнение кожи с омолаживающим эффектом. Благодаря своей инновационной pre-age рецептуре дополнительно противостоит признакам преждевременного старения. Приятная альтернатива «уколам красоты» биоревитализации и мезотерапии.
          </div>
        </div>
        <WaveDivider />
        <ProductGrid>
          <ProductCard
            title="ФЛЮИД ДВУФАЗНЫЙ УВЛАЖНЯЮЩИЙ"
            description="Уникальный 2-х фазный концентрат с гиалуроновой кислотой, миндальным маслом и термальной водой представляет собой утоляющий  „жажду“ кожи. Устраняет шелушение и сухость кожи, тонкие линии и морщины разглаживаются, а цвет лица приобретает свежий, ровный и сияющий вид. Активизирует процесс  увлажнения, с первого применения, насыщая  кожу влагой и сохраняя эффект на 12 часов."
            imgSrc="/assets/images/productlines/deepmoistdepot/dmd_2.png"
          />
          <ProductCard
            title="ФЛЮИД ДВУФАЗНЫЙ УВЛАЖНЯЮЩИЙ ДЛЯ КОЖИ ОБЛАСТИ ГЛАЗ 8МЛ"
            description="ФЛЮИД для идеально увлажненной области вокруг глаз! Сыворотка состоит из 2-х фаз: масляной (белого цвета)  и водной (оранжевого цвета). Масляная фаза содержит масло миндаля, водная - гиалуроновую кислоту, экстракт водорослей, экстракт шелкового дерева. Это не только красиво, но и эффективно! 
            Интенсивно увлажняет кожу.
            Влага сохраняется в коже в течение длительного времени.
            Уменьшаются легкая отечность и признаки темных кругов под глазами.
            Стойкое увлажнение и лифтинг кожи.
            Содержит гиалуроновую кислоту, экстракт шелкового дерева, масла миндаля - активный коктейль для гарантированного лифтинга и тонуса нежной кожи области глаз.
            "
            imgSrc="/assets/images/productlines/deepmoistdepot/dmd_1.png"
          />
          <ProductCard
            title="ЭКСТРА-УВЛАЖНЯЮЩИЙ НОЧНОЙ КРЕМ"
            description="Экстра – увлажняющий Ночной крем для эффективного  восстановления кожи во время ночного ухода, укрепляет естественные защитные механизмы кожи и пополняет ее запасы влаги. Такой энергетический заряд, устраняет признаки усталости кожи, делая ее упругой и шелковистой. 
            АКТИВИРОВАННЫЙ УГОЛЬ известен в косметике своими очищающими и детоксифицирующими свойствами, а также обладает антибактериальным эффектом. В нашем ночном креме угольный порошок дарит нотку изысканности и необычности в каждодневный уход за кожей. 
            ГИАЛУРОНОВАЯ КИСЛОТА- считается лучшим гидродиспенсером среди экспертов по красоте. Она обеспечивает кожу ценной влагой в верхних слоях, поддерживает NMF  (естественный увлажняющий фактор) и защищает кожу  от потери влаги. 
            МИНДАЛЬНОЕ МАСЛО способствует упругости кожи, разглаживает сухие шелушащиеся участки, питая кожу, делая шелковистой  и мягкой. 
            ЭКСТРАКТ ВОДОРОСЛЕЙ богат полисахаридами и минералами, повышает водный баланс кожи и увлажняет ее вплоть до нижних слоев эпидермиса с длительным увлажняющим эффектом.
            "
            imgSrc="/assets/images/productlines/deepmoistdepot/dmd_3.png"
          />
          <ProductCard
            title="ДНЕВНОЙ УВЛАЖНЯЮЩИЙ КРЕМ-СИЯНИЕ"
            description="Оживляющий кожу и придающий ей сияние дневной крем со светозащитным фактором SPF 10. Нежный,легкий крем  тройного действия: увлажнение - гиалуроновая кислота+миндальное масло+пептиды восполняют дефицит влаги в коже; антимимический компонент - ГЕКСАПЕПТИД-19 сглаживает мелкие мимические морщинки и линии; сияние -  светоотражающие пигменты усиливают естественное сияние кожи, придавая ей мгновенно свежий вид; защита- витамин Е (токоферола ацетат) укрепляет сопротивляемость кожи к негативным факторам окружающей среды, а светозащитный фактор  SPF 10 не позволит солнечным лучам нанести вред Вашей коже. ГИАЛУРОНОВАЯ КИСЛОТА- считается лучшим гидродиспенсером среди экспертов по красоте. Она обеспечивает кожу ценной влагой в верхних слоях, поддерживает NMF  (естественный увлажняющий фактор) и защищает кожу  от потери влаги. 
            МИНДАЛЬНОЕ МАСЛО способствует упругости кожи, разглаживает сухие шелушащиеся участки, питая кожу, делая шелковистой  и мягкой. 
            ЭКСТРАКТ ВОДОРОСЛЕЙ богат полисахаридами и минералами, повышает водный баланс кожи и увлажняет ее вплоть до нижних слоев эпидермиса с длительным увлажняющим эффектом.
            "
            imgSrc="/assets/images/productlines/deepmoistdepot/dmd_4.png"
          />
        </ProductGrid>
      </div>
    </>
  );
};

export default DeepMoistDepot;