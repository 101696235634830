import ProductCard from "../../components/ProductCard";
import ProductGrid from "../../components/ProductGrid";
import ProductLinePageTitle from "../../components/ProductLinePageTitle";
import ProductLinePoster from "../../components/ProductLinePoster";
import Divider from "../../components/ui/Divider";
import WaveDivider from "../../components/ui/WaveDivider";
import styles from "./AquaClearSkin.module.css";

interface Props { }

const Thermal: React.FunctionComponent<Props> = (props: Props) => {
  return (
    <>
      <ProductLinePageTitle title="Термальный спрей Баден-Баден" />
      <ProductLinePoster
        imgSrc="/assets/images/productlines/thermal/thermal_poster.jpg"
        imgAlt="AquaClearSkin"
      />
      <Divider />
      <div className={styles.container}>
        <div className={styles.title}>
          НАСТОЯЩИЙ ЭЛЕКСИР КРАСОТЫ
        </div>
        <WaveDivider />
        <div className={styles.descriptionContainer}>
          <img
            className={styles.filler}
            src="/assets/images/productlines/thermal/thermal_2.png"
            alt="filler"
          />
          <div className={styles.description}>
          В самом сердце Шварцвальда, в окружении благоухающей природы, находится старейший легендарный источник термальной воды Фридрихсбад, которая залегает на глубине 2000 метров под Флорентийской горой. На своем пути к поверхности, проходя через слои горных пород, вода забирает лучшие элементы и обогащается ценными минералами и микроэлементами (натрий, калий, кальций, магний, железо, марганец, кремниевая кислота (кремний), хлориды, фториды) и поддерживает естественный обмен веществ кожи, тонизирует и дарит ощущение комфорта.  Это превращает термальную воду в настоящий эликсир красоты вашей кожи. Термальная вода Baden-Baden является сердцем всех продуктов марки SANS SOUCIS.
          </div>
        </div>
        <WaveDivider />
        <ProductGrid>
          <ProductCard
            title="УДОБЕН В ИСПОЛЬЗОВАНИИ"
            description="Тонкая, нежная вуаль мелкодисперсионного термального спрея BADEN-BADEN защищает,  освежает и дарит живительную влагу нашей коже!"
            imgSrc="/assets/images/productlines/thermal/thermal_filler.png"
          />
          <ProductCard
            title="ОБОГАЩЕННЫЙ МИКРОЭЛЕМЕНТАМИ"
            description="На своем пути к поверхности, проходя через слои горных пород, вода забирает лучшие элементы и обогащается ценными минералами и микроэлементами (натрий, калий, кальций, магний, железо, марганец, кремниевая кислота (кремний), хлориды, фториды) и поддерживает естественный обмен веществ кожи, тонизирует и дарит ощущение комфорта."
            imgSrc="/assets/images/productlines/thermal/thermal_1.png"
          />
          <ProductCard
            title="УВЛАЖНЕНИЕ КОЖИ"
            description="Термальный спрей Baden-Baden обеспечивает кожу ценной влагой и свежестью, снимает покраснение, легкое раздражение и успокаивает кожу. Он благотворно действует при солнечных ожогах, стимулирует обновление клеток, увлажняет  и укрепляет кожу."
            imgSrc="/assets/images/productlines/thermal/thermal_3.jpg"
          />
        </ProductGrid>
      </div>
    </>
  );
};

export default Thermal;