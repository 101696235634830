import ProductCard from "../../components/ProductCard";
import ProductGrid from "../../components/ProductGrid";
import ProductLinePageTitle from "../../components/ProductLinePageTitle";
import ProductLinePoster from "../../components/ProductLinePoster";
import Divider from "../../components/ui/Divider";
import WaveDivider from "../../components/ui/WaveDivider";
import styles from "./DailyVitamins.module.css";

interface Props { }

const DailyVitamins: React.FunctionComponent<Props> = (props: Props) => {
  return (
    <>
      <ProductLinePageTitle title="Daily Vitamins" />
      <ProductLinePoster
        imgSrc="/assets/images/productlines/dailyvitamins/vitamins_poster.jpg"
        imgAlt="Cleansing"
      />
      <Divider />
      <div className={styles.container}>
        <div className={styles.title}>
          СИЛА ВИТАМИНОВ
        </div>
        <WaveDivider />
        <div className={styles.descriptionContainer}>
          <img
            className={styles.filler}
            src="/assets/images/productlines/dailyvitamins/vitamins_filler.png"
            alt="filler"
          />
          <div className={styles.description}>
            Кто этого не знает? Стресс, усталость и внешнее воздействие окружающей среды делают нашу кожу уязвимой. Наши ЕЖЕДНЕВНЫЕ ВИТАМИНЫ - это «витаминные бомбы», собранные в комплексы  для целенаправленного использования в качестве оптимального ежедневного ухода за кожей. Сбалансированное питание важно не только для здоровья, но и для нашего внешнего вида. С хорошей порцией витаминов мы можем долго сохранять нашу молодость и  красоту.
            Не имеет значения, обезвожена ли кожа или чувствительна - для каждой потребности мы предлагаем правильный продукт и уход.

          </div>
        </div>
        <WaveDivider />
        <ProductGrid>
          <ProductCard
            title="СС КРЕМ SPF 20"
            description="«Умный» крем способен подстроиться к любому тону кожи - от фарфорового до оливкового цвета. Легкий крем-корректор благодаря своей нежнейшей текстуре идеально наносится, специальные оптические пигменты дарят восхитительный цвет лица, а трипептиды в рецептуре препарата позволяют эффективно бороться с процессами старения. Крем с ЗЕЛЕНЫМ ПОДТОНОМ идеально маскирует куперозную сетку и покраснения!"
            imgSrc="/assets/images/productlines/dailyvitamins/vitamins_2.png"
          />
          <ProductCard
            title="DD КРЕМ ДНЕВНОЙ ЗАЩИТНЫЙ 25 SPF"
            description="DD - крем c гиалуроновой кислотой, витаминами С и Е, маслом абрикоса и термальной водой Баден-Бадена - это сочетание тонального крема и уходовой косметики. Инкапсулированные цветные и светоотражающие пигменты соприкасаясь с кожей, моментально и естественно подстраиваются под ее тон. Легко и естественно , без эффекта маски!   Эффективная борьба с проявлениями старения кожи: моментально увлажняет, маскирует недостатки,  выравнивает тон,  защита от солнца  25  SPF-фактор
            МАСЛО КОСТОЧЕК АБРИКОСА c высокой концентрацией линолевой кислоты уменьшает тонкие линии, вызванные сухостью.
            ВИТАМИННЫЙ КОМПЛЕКС-Нормализует деление и рост клеток кожи, уменьшает тонкие линии и морщины. Укрепляет естественные защитные механизмы кожи и повышается уровень увлажненности. 
            "
            imgSrc="/assets/images/productlines/dailyvitamins/vitamins_4.png"
          />
          <ProductCard
            title="КРЕМ ВИТАМИНИЗИРУЮЩИЙ МУЛЬТИ-ЗАЩИТНЫЙ ДЛЯ 24Ч УХОДА"
            description="Крем увлажняющий 24-ч. ухода с ценным экстрактом папайи, пальмитатом витамина А и ацетатом витамином Е, и термальной водой защищает клетки кожи от вредных воздействий окружающей среды, предотвращает потерю влаги. 
            ПАПАЙЯ- насыщена витаминами А, С и Е,  бета-каротином и поддерживает регенерацию клеток кожи, обладает антиоксидантным действием, минимизирует морщины, увлажняет и осветляет цвет лица. 
            ЭКСТРАКТ ЦВЕТКОВ ПЕРСИКА защищает кожу от действия свободных радикалов, укрепляет ее собственную защитную кислотную мантию и поддерживает регенерацию клеток кожи и способствует естественному сиянию. 
            ВИТАМИННЫЙ КОМПЛЕКС способствует здоровому делению и росту клеток кожи, уменьшению тонких линий и морщин. Укрепляет естественные защитные силы кожи, повышает уровень влаги. 
            "
            imgSrc="/assets/images/productlines/dailyvitamins/vitamins_5.png"
          />
          <ProductCard
            title="ВИТАМИНИЗИРУЮЩИЙ ДЕТОКС-КРЕМ 24Ч УХОДА"
            description="Нежный крем 24 ч ухода с натуральным оливковым маслом, пальмитатом витамина А и ацетатом витамина  Е, термальной водой стимулирует естественный процесс детоксикации кожи, оказывает успокаивающее действие и защищает кожу от воздействия окружающей среды, загрязняющих веществ и свободных радикалов. Уменьшаются ощущения стянутости и раздражения, гармонизируется тон кожи. 
            МАСЛО ОЛИВЫ – оказывает противовоспалительное, очищающее  и стимулирующее  действие. Оно медленно впитывается в роговой слой кожи и обладает смягчающим действием, что делает его идеальным для ухода за грубой и шелушащейся кожей, а высокое содержание олеиновой кислоты делает его идеальным  защитным и увлажняющим фактором.
             КРЕСС-САЛАТ богат витаминами и микроэлементами. Благодаря высокому содержанию витаминов является эффективным защитным щитом от свободных радикалов, укрепляет и оживляет кожу, обладает очищающим  и детокс-действием.
            "
            imgSrc="/assets/images/productlines/dailyvitamins/vitamins_6.png"
          />
          <ProductCard
            title="ВИТАМИНИЗИРУЮЩИЙ АНТИОКСИДАНТНЫЙ КРЕМ 24Ч УХОДА"
            description="Насыщающий  крем 24-часовойго ухода с экстрактом винограда, пальмитатом витамина А и ацетатом Е термальной водой защищает кожу от вредных воздействий свободных радикалов, укрепляет ее сопротивляемость и драгоценную влагу. Повышается  плотность и эластичность кожи, оптимизируется структура кожи и предотвращается преждевременное старение кожи, вызванное окружающей средой.
            ЭКСТРАКТ КОСТОЧЕК ВИНОГРАДА содержит микроэлементы и витамины, идеально защищает кожу от свободных радикалов. Содержащиеся в нем витамины стимулируют микроциркуляцию и сохраняют влагу кожи. 
           ЭКСТРАКТ ГИБИСКУСА - содержащиеся в нем активные ингредиенты ингибируют расщепляющий коллаген фермент эластазу и, тем самым, противодействуют потере эластичности кожи.   
           ВИТАМИННЫЙ КОМПЛЕКС способствует здоровому делению и росту клеток кожи, уменьшению тонких линий и морщин. Укрепляет естественные защитные силы кожи, повышает уровень влаги. 
           "
            imgSrc="/assets/images/productlines/dailyvitamins/vitamins_7.png"
          />
          <ProductCard
            title="АНТИВОЗРАСТНОЙ УХОД ДЛЯ ЗРЕЛОЙ КОЖИ-ЛЮКС КРЕМ"
            description="Укрепляющий крем 24-часовго уход а с комплексом ценных масел, пальмитатом витамина А и ацетатом Е, маслом ши и термальной водой уменьшает тонкие линии и морщины, поддерживает эластичность и упругость кожи и защищает от вредного воздействия свободных радикалов. Предотвращает провисание контуров лица, улучшает регенерацию кожи и стабилизирует влажность кожи. 
            КОМПЛЕКС МАСЕЛ-состоит из масла шиповника и сквалана, поддерживает эластичность кожи и активизирует ее регенерацию, защищает от вредного воздействия свободных радикалов, укрепляет естественные функции кожи и стабилизирует гидробаланс кожи.
             МАСЛО ШИ-Питает и разглаживает  кожу, делая ее бархатистой и шелковистой, защищает от потери влаги и сухости.  
            ВИТАМИННЫЙ КОМПЛЕКС-Нормализует деление и рост клеток кожи, уменьшает тонкие линии и морщины. Укрепляет естественные защитные механизмы кожи и повышается уровень увлажненности.
            "
            imgSrc="/assets/images/productlines/dailyvitamins/vitamins_8.png"
          />
          <ProductCard
            title="АНТИВОЗРАСТНОЙ СЕБОРЕГУЛИРУЮЩИЙ КРЕМ"
            description="Легкий  крем 24-часового ухода  с цитрусовым экстрактом, пальмитатом витамина А и ацетатом Е, термальной водой улучшает тонус кожи, поддерживает нормализацию выработки кожного сала и противодействует появлению тонких линий и морщин. Предотвращает воспаления и загрязнения кожи, противодействует чрезмерному ороговению кожи. 
            ЛАЙМ-натуральный кожный антисептик, является антибактериальным противовирусным средством и действует как эффективное дезинфицирующее средство.
             МАСЛО КОСТОЧЕК АБРИКОСА c высокой концентрацией линолевой кислоты уменьшает тонкие линии, вызванные сухостью.
             ВИТАМИННЫЙ КОМПЛЕКС-Нормализует деление и рост клеток кожи, уменьшает тонкие линии и морщины. Укрепляет естественные защитные механизмы кожи и повышается уровень увлажненности."
            imgSrc="/assets/images/productlines/dailyvitamins/vitamins_9.png"
          />
        </ProductGrid>
      </div>
    </>
  );
};

export default DailyVitamins;